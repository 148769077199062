.overview {
    box-sizing: border-box;

    &_box1{

        &-top{

            &-left{
                border: 0.881473px solid #EAECF0;
                box-shadow: 0px 0.881473px 2.64442px rgba(16, 24, 40, 0.1), 0px 0.881473px 1.76295px rgba(16, 24, 40, 0.06);
                border-radius: 7.05178px;
                padding: 10px;
                margin-left: auto;
                position: relative;

            }
        }
    }
}