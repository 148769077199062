.billing{
    box-sizing: border-box;

    &_nav{
        font-size: 1.1em;
        font-weight: 600;
        display: flex;
        margin-bottom: 20px;

        p{
            padding: 5px 10px;
            cursor: pointer;
            color: #000;
        }
        .active{
            border-bottom: 3px solid $color-primary;
        }
    }

    &_spectrum{
        display: flex;
        @media(max-width: $desktop){
            display: block;
        }

        &-sidebar{
                width: 200px;
                @media(max-width: $desktop){
                    display: flex;
                    width: 100%;
                    margin-bottom: 20px;
                    flex-wrap: wrap;
                }

                p{
                    font-weight: 600;
                    color: #344054;
                    padding: 10px;
                    margin: 0;
                    cursor: pointer;
                    @media(max-width: $desktop){
                        width: 150px;
                    }
                    @media(max-width: $tab-port){
                        width: 45%;
                    }
                    @media(max-width: 500px){
                        width: 100%;
                    }

                    img{
                        width: 20px;
                        margin-right: 7px;
                    }
                }

                .active{
                    border-bottom: 1px solid $color-primary;
                    border-left: 3px solid $color-primary;
                    background: #EAECF0;
                }
        } 

        &-main{
            width: 100%;
            margin-left: 30px;
            @media(max-width: $desktop){
                margin-left: 0px;
            }

            &-top{
                border: 1px solid #E2E2E2;
                border-radius: 6px;
                padding: 25px 20px;
                margin-bottom: 30px;

                &-mail{
                    display: flex;
                    border-bottom: 1px solid #E2E2E2;

                    h5{
                        margin: 0;
                    }

                    p{
                        color: #475467;
                    }

                    &-change{
                        margin-left: auto;
                        cursor: pointer;

                        img{
                            width: 20px;
                            margin: -5px 5px 0 0;
                        }
                    }
                }

                label{
                    color: #475467;
                    margin-top: 20px;

                    input{
                        width: 15px;
                    }
                }
            }

            &-invoice{
                border: 1px solid #E2E2E2;
                border-radius: 6px;

                &-usage{
                    margin: 20px;
                    box-sizing: border-box;
                }

                &-table{
                    border: 1px solid #E2E2E2;
                    margin-top: 7px;
                    border-radius: 10px 10px 0 0;

                    &-scroll{
                        overflow-x: scroll;
                    }

                    table{
                        width: 100%;
                        padding-top: 10px;
                        color: #667085;
                        @media(max-width: $desktop){
                            width: 1000px;
                        }

                        tr{
                            border-bottom: 1px solid #E2E2E2;

                            th{
                                padding: 10px;
                                font-size: 0.9em;
                                font-weight: 400;
                                // background: #F9FAFB;
                            }

                            td{
                                padding: 10px;
                            }
                        }

                    }
                }
            }
        }
    }
}