//  Specific breakpoints for responsiveness
$desktop: 1200px;
$tab-land: 992px;
$tab-port: 769px;
$mobile: 560px; 


// Colors
$color-primary: #296AFC;
$color-sidebar: #0046E6;
$color-grey: #515151;
$color-btnInactive: #D0D0D0;
$color-formInput: #8A8A8A;
$color-toggleGreen: #2BBD35;
$color-online: #087F00;
$color-activeLink: #427CFF;
$color-textMain: #0F123F;
$color-text2: #BABEC6;
$color-text3: #2C2C2C;
$color-text4: #5A5A5A;
$color-text5: #353535;


// Fonts
$font-title: 'DM Sans', sans-serif;
$font-main: 'Mulish', sans-serif;