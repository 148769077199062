.dashboard{
    &_sidebar{
        @media(max-width: $tab-land){
            transition: 2s;
        }

        &-main{
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease-out;
            /* overflow-y: scroll; */
        }
    }
    
    &_main{

        &-alert{
            display: flex;
            justify-content: space-between;
            width: 65%;
            // background: #FFFCF5;
            border-radius: 8px;
            padding: 12px 15px;
            margin: 20px 0 0 40px;
            text-decoration: none;

            a{
                text-decoration: none;
            }

            span{
                display: flex;

                p{
                    margin: 0;
                    padding: 0 10px;
                    // color: #B54708;
                }
                
            }
        }
    }

    &_back{
        @media(max-width: $tab-land){
            display: none;
        }
    }
}