@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2, h3,h4, h5,h6{
  font-family: 'DM Sans', sans-serif;
}

p, span, a, button{
  font-family: 'Mulish', sans-serif;
}

input:focus, select:focus{
  outline: none;
}

.link-decor{
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}



/* Hide scroll bar */
.hide-scrollBar::-webkit-scrollbar {
  display: none;
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

/* hide arrow in number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}


/* Style for toggle button to switch betwen live and test */
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  width: 60px;
  height: 28px;
  /* margin: -20px auto 0 auto; */
  overflow: hidden;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #CDCDCD;
  transition: 0.3s ease all;
  z-index: 1;
}

.button-1 .knobs:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 6px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

.button-1 .checkbox:checked + .knobs:before {
  content: "";
  left: 33px;
  background-color: white;
}

.button-1 .checkbox:checked ~ .layer {
  background-color: #2BBD35;
}

.button-1 .knobs,
.button-1 .knobs:before,
.button-1 .layer {
  transition: 0.3s ease all;
}


/* To move the virtual cards on income page */
.cards-left-arrow {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  height: 220px;
  width: 50px;
  z-index: 2000;
  cursor: pointer;
}
.cards-right-arrow {
  background: rgba(0, 0, 0, 0.3);
  right: 0;
  position: absolute;
  height: 220px;
  width: 50px;
  z-index: 2000;
  cursor: pointer;
}
.cards-left-arrow img, .cards-right-arrow img {
  display:block;
  margin: 100px auto 0;
}
.card-scroll{
  display: flex;
  overflow-x: scroll;
}
.card-scroll::-webkit-scrollbar {
  width: 0;  
  background: transparent; 
}




/* bootstrap accordion changes */
.accordion-item {
    border: 0;
}

.accordion-button{
    display: block;
    padding: 0;
}
.accordion-button:not(.collapsed) {
    color: black;
    background-color: white;
    
}
.accordion-button:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
}


/* react-date-range style over ride */
.rdrMonth{
  width: 100% !important;
}


/* hover animation button */
@keyframes bounceUp {
  0% { -webkit-transform: translateY(0);}
  100% { -webkit-transform: translateY(-0.4em); }
}

.bounceup-animate:hover{
  -webkit-animation: bounceUp 0.6s infinite alternate ease-in-out;
}


/* Line loader loop for tables */
.loader-loop {
  animation: 1.5s infinite loop cubic-bezier(0.420, 0.000, 0.580, 1.000);
}
@keyframes loop {
  0% {
    width: 0%;
    transform: translateX(0%);
  }
  7% {
    width: 10%;
    transform: translateX(0%);
  }
  40% {
    width: 60%;
  }
  50% {
    transform: translateX(85%);
  }
  
  75% {
    width: 100%;
  }
  100% {
    transform: translateX(100%);
  }
}


/* page loader animation */
.page-loader {
  animation: pulse 1s linear infinite;
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  50% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


/* React date picker */
.react-date-picker__wrapper{
  border-width: 0px !important;
}


/* toggle button component style */
.toggle_button .layer {
    background-color: #CDCDCD;
    transition: 0.3s ease all;
}

.toggle_button .button-2 .knobs:before {
    content: "h";
    position: absolute;
    top: 3px;
    left: 4px;
    width: 21px;
    height: 22px;
    color: white;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    background-color: white;
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

.toggle_button .button-2 .checkbox:checked + .knobs:before {
    content: "";
    left: 35px;
    background-color: white;
}

.toggle_button .button-2 .checkbox:checked ~ .layer {
    background-color: #2BBD35;
}

.toggle_button .button-2 .knobs,
.toggle_button .button-2 .knobs:before,
.toggle_button .button-2 .layer {
    transition: 0.3s ease all;
}


/* checkbox component for widget setup modal */
.setup_check span:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #0046E6;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 7px;
  display: inline-block;
  border-radius: 4px;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.setup_check input:checked + span:after {
  content: '';
  display: block;
  position: absolute;
  top: 7px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid #0046E6;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* radio button styles for widget setup */
.setup_radio [type="radio"]:checked,
.setup_radio [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.setup_radio [type="radio"]:checked + label,
.setup_radio [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #344054;;
    font-weight: 600;
}
.setup_radio [type="radio"]:checked + label:before,
.setup_radio [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #0046E6;
    border-radius: 100%;
    background: #fff;
}
.setup_radio [type="radio"]:checked + label:after,
.setup_radio [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #0046E6;
    position: absolute;
    top: 3px;
    left: 3.5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.setup_radio [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.setup_radio [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}


/* react toggle style override */
.react-toggle-thumb {
  box-shadow: none !important;
}
/* app section toggle */
.apps-toggle .react-toggle-track{
  background-color: #DC6803 !important;
}

.apps-toggle.react-toggle--checked .react-toggle-track {
  background-color: #2BBD35 !important;
}


/* credit insight business search scroll bar */
.businessSearch-scroll::-webkit-scrollbar {
  width: 6px;
}

.businessSearch-scroll::-webkit-scrollbar-track {
  background: transparent;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
}

.businessSearch-scroll::-webkit-scrollbar-thumb {
  background: #B1B6BA4F;
  border-radius: 8px;
}

/* bootstrap modal */
.modal-content {
  border-radius: 12px !important;
}

/* download-filter-daterange */
.download-filter-daterange .rdrMonthName {
  text-align: center;
  color: #141414;
}

.download-filter-daterange .rdrWeekDay {
  color: #1F1F1F;
}

.download-filter-daterange .rdrDayNumber {
  border-radius: 4px;
  margin: 0 2px;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
}

.download-filter-daterange .rdrDayPassive .rdrDayNumber {
  box-shadow: none;
}

.download-filter-daterange .rdrDateDisplayWrapper {
  display: none
}

.download-filter-daterange .rdrMonthAndYearWrapper {
  position: relative;
  height: 0;
}

.download-filter-daterange .rdrMonthAndYearPickers {
  display: none
}

.download-filter-daterange .rdrNextPrevButton {
  border-radius: 50%;
  background-color: white;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
}

.download-filter-daterange .rdrPprevButton {
  position: absolute;
  top: 16px;
  left: 0;
}

.download-filter-daterange .rdrNextButton{
  position: absolute;
  top: 16px;
  right: 0
}

