.credit{
    margin: 0 30px 50px;
    box-sizing: border-box;

    @media(max-width: 575px){
        margin: 0 30px 50px 5px;
    }

    &-bvn{
        margin: 0 0 50px;

        &-form{
            display: flex;
            position: relative;
            @media(max-width: $mobile){
                display: block;
            }

            .notvisible{
                margin-top: 7px !important;
            }
        }

        &-input{
            width: 300px;
            height: 40px;
            border: 1px solid #DADADA;
            border-radius: 4px;
            margin-right: 10px;
            padding: 3px 15px;
            color: #8A8A8A;
            @media(max-width: $mobile){
                width: 100%;
            }

            &-show{
                top: 10px;
                left: 270px;
                position: absolute;
                right: 20px;
                width: 20px;
                cursor: pointer;
                @media(max-width: $mobile){
                    right: 10px;
                    left: auto;
                }
            }
        }
    

        button{
            background: $color-sidebar ;
            border: 1px solid $color-sidebar;
            border-radius: 5px;
            height: 40px;
            width: 90px;
            text-align: center;
            color: white;
            @media(max-width: $mobile){
                margin-top: 10px;
            }
        }

        &-btnInactive{
            background: $color-formInput !important ;
            border: 1px solid $color-formInput !important;
        }
    }

    &_classic{
        margin: 50px 0 0;
    }

    &_fico{
        background: #FFFFFF;
        border: 0.15px solid #4C8BF5;
        box-sizing: border-box;
        box-shadow: 0px 4px 90px rgba(163, 171, 185, 0.24);
        border-radius: 15px;
        margin: 0 0 30px;

        hr{
            border: 0.5px solid #0046E6;
        }

        &-single{
            padding: 15px;

            &-img{
                background: #0046E6;
                width: 50px;
                height: 50px;
                margin: 0 0 10px;
                border-radius: 50%;
                img{
                    width: 35px;
                    margin: 7px 0 0 8px;
                }
            }

            &-top{
                img{
                    width: 30px;
                    margin: -15px 10px 0 0;
                }
                p{
                    font-size: 1em;
                    font-weight: 700;
                    opacity: 0.5;
                    margin: 0 !important;
                }
            }

            &-text{
                font-size: 1.8em;
                font-weight: 700;
                margin: 0 !important;
            }

            &-flex{
                display: flex;
                .average{
                    color: #FF8B03;
                }
                .good{
                    color: #47D800;
                }
                .bad{
                    color: #FF2608;
                }

                .more{
                    margin: 7px  0 0 auto;
                    font-weight: 700;
                    font-size: 1em;
                    opacity: 0.5;
                    cursor: pointer;
                    img{
                        margin: -15px 0 0 10px;
                    }
                }
            }

            &-reasons{
                font-size: 1em;
                opacity: 0.5;
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.6s ease-out;
            }
        }

        &-wrapper{
            border-radius: 10px;
            margin: 15px 0 ;
            padding: 0 !important;
            border: 2px solid #0046E6;
        }

        &-chart{
            &-text{
                display: flex;
                align-items: center;
                p{
                    margin: 0 0 0 10px;
                    font-weight: 700;
                    font-size: 1.3em;
                    color: #4C8BF5;
                }
            }

            &-main{
                // border: 1px solid red;
                position: relative;
                margin-top: 50px;
                overflow: hidden;

                .chart{
                    width: 90%;
                    display: block;
                    margin: auto;
                }
                .guage{
                    border-width: 0px;
                    height: 4px;
                    width: 60%;
                    left: 20%;
                    background-color: black;
                    position: absolute;
                    // transform: rotate(90deg);
                    background-color: #000000;
                    background-image: linear-gradient(270deg, #ffffff 50%, #000000 50%);

                    bottom: 5px;
                    border-radius: 15px;
                }
            }
        }
    }

    &_boxes{
        box-sizing: border-box;
        margin: 30px 20px 30px 0;

        &-title{
            margin: 0 0 40px;
            @media(min-width: $desktop){
                display: flex;
            }

            &-info{
                padding: 15px 0 0;
                font-weight: 600;
                p{
                    margin: 0 20px 5px 0;
                }

                &-flex{
                    display: flex;
                }
            }

            span{
                font-size: 15px;
                margin-left: 10px;
                font-weight: 400;
            }

            &-buttons{
                margin-left: auto;
                button{
                    border: 1px solid #0046E6;
                    box-sizing: border-box;
                    border-radius: 7px;
                    padding: 5px 15px;
                    font-size: 1em;
                    background: #FFFFFF;
                    img{
                        width: 20px;
                        margin: -3px 4px 0 0;
                    }
                }

                .share{
                    margin: 0 10px 0 0;
                }
            }
        }

        &-single{
            background: #FFFFFF;
            border: 0.15px solid #4C8BF5;
            box-sizing: border-box;
            box-shadow: 0px 4px 90px rgba(163, 171, 185, 0.24);
            border-radius: 15px;
            padding: 15px;
            margin: 0 0 30px;

            &-top{
                display: flex;
                img{
                    width: 30px;
                    margin: -15px 10px 0 0;
                }
                p{
                    font-size: 1em;
                    font-weight: 700;
                    opacity: 0.5;

                    span{
                        font-size: 0.85em;
                        font-weight: 500;
                    }
                }
            }

            &-text{
                font-size: 1.3em;
                margin: 0 !important;
            }

            &-patternIcon{
                margin: -10px 0 0 auto !important;
                width: 40px !important;
            }

            &-patternText{
                color: #1F4173;
                font-size: 1.3em;
                margin: 0 !important;
            }
        }

        &-singleNew{
            background: #FFFFFF;
            border: 0.717542px solid #EAECF0;
            box-shadow: 0px 0.717542px 2.15263px rgba(16, 24, 40, 0.1), 0px 0.717542px 1.43508px rgba(16, 24, 40, 0.06);
            box-sizing: border-box;
            border-radius: 15px;
            padding: 15px;
            margin: 0 0 30px;

            &-top{
                display: flex;
                height: 45px;
                align-items: flex-start;

                img{
                    width: 35px;
                    height: 35px;
                    margin-right: 5px;
                    @media(min-width:1200px){
                        width: 30px;
                        height: 30px;
                    }
                    
                    @media(min-width:1300px){
                        width: 35px;
                        height: 35px;
                    }
                }
                p{
                    font-size: 1em;
                    font-weight: 600;
                    color: #101828;
                    @media(min-width:1200px){
                        font-size: 0.92em;
                    }
                    
                    @media(min-width:1300px){
                        font-size: 1em;
                    }
                }
            }

            &-text{
                font-size: 1.5em;
                font-weight: 700;
                margin: 0 !important;
            }
        }
    }

    &_enquiry{
        &-topText{
            font-size: 1.2em;
        }
        
        &-data{
            border: 1px solid #EAECF0;
            box-sizing: border-box;
            box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
            border-radius: 0 0 10px 10px !important;
            margin: 0 0 40px;

            table{
                width: 100%;
            }

            tr{
                color: #0F123F;
                font-size: 1em;
                border-bottom: 1px solid #EAECF0;
    
                @media(max-width: $mobile){
                    font-size: 0.9em;
                }
            }
            th, td{
                padding: 10px;
            }
            th{
                opacity: 0.56;
                font-weight: 400;
                text-transform: capitalize !important;
            }

            .view{
                border: 1px solid #47D800;
                background-color: #47D800;
                border-radius: 4px;
                margin-right: 10px;
                color: white;
                width: 100px;
                img{
                    margin-bottom: 3px;
                    margin-right: 8px;
                }
            }
        }
    }
}