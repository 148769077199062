.float{

    &_overview{
        margin: -50px 0 0;
        box-sizing: border-box;
        @media(max-width: $desktop) {
            margin: -15px 0px 0;
        }
        @media(max-width: $tab-land) {
            margin: -15px 40px 0;
        }
        @media(max-width: 650px) {
            margin: -15px 0px 0;
        }

        &-topNav{
            display: flex;
            margin-bottom: 30px;
            p{
                font-weight: 600;
                cursor: pointer;
                color: black;
                @media(max-width: 500px) {
                    margin: 0 15px 0 0;
                }

                img{
                    margin: -3px 5px 0 0;
                    @media(max-width: 500px) {
                        display: none;
                    }
                }
            }

            .active{
                border-bottom: 3px solid #427CFF;
            }
        }

        &-box{
            border: 0.760194px solid #EAECF0;
            box-shadow: 0px 0.760194px 2.28058px rgba(16, 24, 40, 0.1), 0px 0.760194px 1.52039px rgba(16, 24, 40, 0.06);
            border-radius: 9.12233px;
            padding: 20px;
            min-height: 150px;
            margin-bottom: 30px;
            position: relative;

            .text1{
                color: #808080;
            }
            .text2{
                color: $color-sidebar;
                text-decoration: underline;
                cursor: pointer;
                margin: 0;
            }
            button{
                border-width: 0px;
                color: white;
                border-radius: 4px;
                padding: 5px 16px;
            }

            &-dropdown{
                position: absolute;
                bottom: -1.5rem;
                button{
                    background: #82a7ff ;
                    border: 1px solid #608ffb;
                    color: white;
                    border-radius: 4px;
                    padding: 5px 16px;
                }
                 
            }
        }
    }

    &_request{
        box-sizing: border-box;
        margin: 0;
        @media(max-width: $desktop) {
            margin:  0;
        }
        @media(max-width: $tab-land) {
            margin: 0 40px;
        }
        @media(max-width: 650px) {
            margin:  0;
        }

        &-box{
            border: 1px solid #E6EDFF;
            box-shadow: 0px 1px 4px rgba(127, 86, 217, 0.2);
            border-radius: 8px;
            padding: 20px;
            margin-top: 30px;
            @media(max-width: $mobile){
                overflow-x: scroll;
            }

            table{
                width: 100%;
                
                @media(max-width: $mobile){
                    min-width: 600px;
                }
        
                tr{
                    border-bottom: 0.75px solid #EAECF0 !important;
                    color: #0F123F;
                    font-size: 1em;
        
                    @media(max-width: $mobile){
                        font-size: 1em;
                    }
                }
                th, td{
                    padding: 10px 5px 10px 10px;
                }
                th{
                    opacity: 0.56;
                    font-weight: 400;
                    color: #06152B;
                    font-size: 0.9em;
                    text-transform: capitalize !important;
                }
        
                thead{
                    border-bottom: 1px solid #EAECF0 !important;
                }
            }
        }

        &-item{
            display: flex;
            .text1{
                font-weight: 600;
                color: #9E9E9E;
                margin-right: 10px;
            }
            .text2{
                font-weight: 600;
                color: #344054;
                margin-left: auto;
                text-transform: capitalize;
            }
        }

        &-checks{
            display: flex;
            align-items: flex-start;
            margin-top: 10px;

            input{
                width: 50px;
                margin: 4px 7px 0 -15px;
            }

            p{
                margin: 0 0 0 -20px;
                color: #344054;
                font-size: 0.9em;
            }
        }
    }

    &_modal{
        padding: 20px;
        box-sizing: border-box;

        &-options {
            margin: 15px 0 0;
            h5 {
                font-family: 'DM Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 25px;
                margin: -15px 0 20px;
                @media (max-width: 560px) {
                    font-size: 20px;
                    line-height: 28px;
                }
            }

            p {
                font-weight: 500;
                font-size: 16px;
                line-height: 50px;
                @media (max-width: 560px) {
                    font-size: 12px;
                    line-height: 20px;
                }
            }

            .radio{
                [type="radio"]:checked,
                [type="radio"]:not(:checked) {
                    position: absolute;
                    left: -9999px;
                }
                [type="radio"]:checked + label,
                [type="radio"]:not(:checked) + label
                {
                    position: relative;
                    padding-left: 28px;
                    cursor: pointer;
                    line-height: 20px;
                    display: inline-block;
                    color: #344054;;
                    font-weight: 600;
                }
                [type="radio"]:checked + label:before,
                [type="radio"]:not(:checked) + label:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 18px;
                    height: 18px;
                    border: 1px solid $color-sidebar;
                    border-radius: 100%;
                    background: #fff;
                }
                [type="radio"]:checked + label:after,
                [type="radio"]:not(:checked) + label:after {
                    content: '';
                    width: 12px;
                    height: 12px;
                    background: $color-sidebar;
                    position: absolute;
                    top: 2.7px;
                    left: 3px;
                    border-radius: 100%;
                    -webkit-transition: all 0.2s ease;
                    transition: all 0.2s ease;
                }
                [type="radio"]:not(:checked) + label:after {
                    opacity: 0;
                    -webkit-transform: scale(0);
                    transform: scale(0);
                }
                [type="radio"]:checked + label:after {
                    opacity: 1;
                    -webkit-transform: scale(1);
                    transform: scale(1);
                }
            }
        }

        &-action{
            padding: 7px;
            text-align: center;
            color: white;
            background: $color-sidebar;
            border: 1px solid $color-sidebar;
            border-radius: 6px;
            width: 100%;
        }

        &-terms{
            margin: 40px 20px;
            ::-webkit-scrollbar {
                width: 10px;
            }
            ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px white; 
            border-radius: 10px;
            }
            ::-webkit-scrollbar-thumb {
                background: #EEEFF0; 
                border-radius: 10px;
            }
            ::-webkit-scrollbar-thumb:hover {
                background: #EEEFF0; 
            }
        }
    }

    &-emailConsent{
        &-header{
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            color: #000000;
            margin-bottom: 1.5em;
        }

        &-label{
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #667085;
        }

        &-terms{
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #667085;
        }
    }
}