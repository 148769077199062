.compliance{
    &_save{
        width: 540px;
        padding: 10px 0;
        text-align: center;
        display: block;
        margin: 20px auto 30px;
        border-radius: 7px;
        border: 1px solid $color-sidebar;
        background: $color-sidebar;
        font-size: 1.1em;
        font-weight: 600;
        color: white;

        @media(max-width: $mobile){
            width: 100%;
        }
    }

    &_profile{
        &-form{
            box-sizing: border-box;

            label{
                font-weight: 600;
                font-family: $font-main;
                margin: 20px 0 5px;
            }
            p, label{
                color: $color-text5;
                font-size: 1em;
            }
            input{
                width: 100%;
                height: 2.5em;
                padding: 5px 15px;
                border: 2px solid #DADADA;
                box-sizing: border-box;
                border-radius: 4px;

                &:focus{
                    border: 2px solid $color-sidebar;
                    outline: none;
                }
            }
        }

        &-info{
            h6{
                color: #5B5B5B;
                margin: 0 !important;
                font-size: 1em;
            }
            p{
                color: $color-text5;
                font-size: 1em;
            }
            button{
                background: #EAEAEA;
                border: 1px solid #696969;
                border-radius: 8px;
                text-align: center;
                padding: 8px 15px;
                width: 50px;
                font-weight: 700;
            }

            &-next{
                float: right;
            }
            
        }
    }
    
    &_alldone{
        margin: 20px auto;
        text-align: center;
    }
}