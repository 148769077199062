.spectrum{
    margin: 30px 25px 30px 38px;
    box-sizing: border-box;

    @media(max-width: $mobile){
        margin: 30px 0px;
    }

    &-topText{
        margin: -50px 12px 40px;

        @media(max-width: 1500px){
            width: 570px;
        }
        @media(max-width: 1400px){
            width: 350px;
        }
        @media(max-width: $desktop){
            width: 100%;
            margin: 40px 0 20px;
        }
    }

    &_overview{
        &-top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;

            @media(max-width: $mobile){
                display: block;
            }

            button{
                color: #344054;
                border: 1px solid #D0D5DD;
                border-radius: 5px;
                padding: 7px 15px;
                font-weight: 600;
                background: white;
            }
        }

        &-shareBox{
            border: 1px solid #D4D8E0;
            border-radius: 7px;
            display: flex;
            min-height: 300px;

            @media(max-width: $mobile){
                display: block;
            }

            &-select{
                width: 50%;
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                @media(max-width: $mobile){
                    width: 100%;
                }
                
                p{
                    cursor: pointer;
                    display: flex;
                    align-items: flex-start;
                    img{
                        width: 20px;
                        margin: 6px 10px 0 0;
                    }
                    input[type=checkbox]{
                        /* Double-sized Checkboxes */
                        -ms-transform: scale(1.3); /* IE */
                        -moz-transform: scale(1.3); /* FF */
                        -webkit-transform: scale(1.3); /* Safari and Chrome */
                        -o-transform: scale(1.3); /* Opera */
                        transform: scale(1.3);
                        padding: 10px;
                        width: 20px;
                        margin: 6px 10px 0 -30px;
                    }
                }
                .inactive-text{
                    margin-left: 30px;
                    color: #98A2B3;
                }
                .active-text{
                    color: #1D2939;
                    font-weight: 700;
                }
            }

            &-content{
                background: #EAF1FD;
                width: 50%;
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                @media(max-width: $mobile){
                    width: 100%;
                }

                p{
                    color: #667085;
                }
                button{
                    background: $color-primary;
                    border: 1px solid $color-primary;
                    border-radius: 6px;
                    color: white;
                    padding: 7px 10px;
                    width: 150px;
                    display: block;
                    margin: auto;
                    font-weight: 600;
                }
            }
        }

        &-video{
            height: 300px;
            background: url('../images/spectrum-video.png');
            border-radius: 10px; 
            background-size: cover;
            background-repeat: no-repeat;
            color: white;
            padding: 20px 30px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            @media(max-width: $desktop){
                margin-top: 30px;
            }

            img{
                width: 50px;
                display: block;
                cursor: pointer;
                margin: auto auto 50px;
            }

            h5{
                margin: 0;
            }
        }

        &-tableNav{
            display: flex;
            margin: 40px 0 0;
            p{
                padding: 10px 20px;
                color: #667085;
                font-weight: 600;
                cursor: pointer;
            }

            .selectedTab{
                color: #003ECC;
                background-color: #E5EEFF;
                border-bottom: 3px solid #003ECC;
            }
        }

        &-dataTable{
            border: 1px solid #EAECF0;
            border-radius: 7px;
            box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
            &-top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px;
                border-bottom: 1px solid #EAECF0;
                @media(max-width: $desktop){
                    display: block;
                }

                h5, p{
                    margin: 0;
                }

                button{
                    color: #344054;
                    border: 1px solid #D0D5DD;
                    border-radius: 5px;
                    padding: 7px 15px;
                    font-weight: 600;
                    width: 150px;
                    background: white;
                    @media(max-width: $desktop){
                        margin-top: 10px;
                    }

                    img{
                        width: 17px;
                        margin: -2px 5px 0 0;
                    }
                }

                &-selection{
                    display: flex;
                    font-weight: 600;
                    cursor: pointer;

                    .search-debt{
                        border: 1px solid #D0D5DD;
                        padding: 4px 10px;
                        border-radius: 5px 0 0 5px;
                    }
                    .contribution{
                        border: 1px solid #D0D5DD;
                        padding: 4px 10px;
                        border-radius: 0 5px 5px 0;
                    }

                    .active{
                        background-color: #D0D5DD;
                    }
                }

                &-input{
                    width: 400px;
                    position: relative;
                    @media(max-width: $desktop){
                        margin-top: 10px;
                    }
                    @media(max-width: $desktop){
                        width: 100%;
                    }

                    input{
                        border: 1px solid #99B8FF;
                        border-radius: 7px;
                        background: white;
                        color: #101828;
                        width: 100%;
                        padding: 7px 10px 7px 30px;
                    }
                    img{
                        width: 17px;
                        position: absolute;
                        top: 11px;
                        left: 7px;
                    }
                }
            }

            &-empty{
                text-align: center;
                padding: 30px 20px 50px;
                max-width: 400px;
                display: block;
                margin: auto;

                &-mainIcon{
                    width: 50px;
                    display: block;
                    margin: auto;
                }

                h5{
                    font-size: 1.1em;
                }

                p{
                    color: #475467;
                }

                .read{
                    color: #344054;
                    border: 1px solid #D0D5DD;
                    border-radius: 6px;
                    padding: 7px 20px;
                    font-weight: 600;
                    background: white;
                    margin: 0 10px 10px 0;
                }

                .import{
                    background: $color-primary;
                    border: 1px solid $color-primary;
                    border-radius: 6px;
                    color: white;
                    padding: 7px 20px;
                    font-weight: 600;
                    img{
                        margin: -3px 4px 0 0;
                    }
                }
            }

            &-loading{
                margin: 100px 0;
                text-align: center;

                img{
                    width: 50px;
                    display: block;
                    margin: auto;
                }
            }

            &-searchForm{
                margin: 30px 15px;
                display: flex;
                align-items: flex-start;

                .input-search{
                    width: 300px;
                }

                button{
                    padding: 7px 10px;
                    margin-left: 10px;
                    color: white;
                    background: $color-primary;
                    border: 1px solid $color-primary;
                    border-radius: 6px;
                    font-weight: 600;
                    width: 100px;
                    text-align: center;
                }
            }
            
            &-main{
                box-sizing: border-box;
                position: relative;
                padding: 0 15px;

                &-scroll{
                    @media(max-width: $desktop){
                        overflow-x: scroll;
                    }
                }


                table{
                    width: 100%;
                    font-weight: 400;
                    font-size: 1em;
                    @media(max-width: $desktop){
                        width: 1400px;
                    }

                    
                    th{
                        color: #475467;
                        font-weight: 400;
                        font-weight: 1.1em;
                        background: #F8F8F8;
                        padding: 6px 10px;
                        border: 1px solid #EAECF0;
                    }

                    td{
                        color: #475467;
                        padding: 6px 10px;
                        border-bottom: 1px solid #EAECF0;

                        .view{
                            color: #344054;
                            border: 1px solid #D0D5DD;
                            border-radius: 6px;
                            padding: 4px 10px;
                            font-weight: 600;
                            background: white;
                            margin: 5px 0;
                            // width: 90px;
                            img{
                                margin: -3px 4px 0 0;
                            }
                            .load{
                                width: 70px;
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    &_pricing{
        &-text{
            display: block;
            margin: auto;
            padding: 20px;
            max-width: 500px;

            &-1{
                font-weight: 500;
                color: $color-primary;
            }

            &-2{
                color: #475467;
                font-size: 1.2em;
            }
        }

        &-tablets{
            background: #F9FAFB;
            border: 1px solid #F2F4F7;
            border-radius: 8px;
            display: flex;
            width: 470px;
            justify-content: center;
            flex-wrap: wrap;
            padding: 5px;
            margin: auto auto 30px;
            cursor: pointer;
            @media(max-width: $mobile){
                width: 330px;
            }

            p{
                margin: 0;
                color: #667085;
                font-weight: 600;
                text-align: center;
                padding: 7px;
            }

            .active{
                box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
                border-radius: 6px;
                background: white;

                p{
                    color: #344054;
                }
            }
            div{
                width: 150px;
            }

        }

        &-card{
            border: 1px solid #EAECF0;
            box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
            border-radius: 10px;
            margin-bottom: 30px;

            &-top{
                padding: 30px 15px;
                border-bottom: 1px solid #EAECF0;
            }

            .text11{
                font-size: 1.1em;
                font-weight: 600;
                color: #475467;
                margin: 0;
            }
            .text12{
                color: $color-primary;
                background: #E5EEFF;
                margin: 0;
                padding: 2px 10px;
                border-radius: 15px;
                margin-left: auto;
                font-size: 0.9em;
                font-weight: 600;
            }

            &-price{
                display: flex;
                h2{
                    font-weight: 700;
                    font-size: 2.6em;
                    margin-right: 5px;
                }
                img{
                    width: 17px;
                    margin: 0;
                    cursor: pointer;
                }
                p{
                    margin: 0;
                }
            }

            &-start{
                width: 100%;
                padding: 7px 10px;
                margin: 20px 0 10px;
                color: white;
                background: $color-primary;
                border: 1px solid $color-primary;
                border-radius: 6px;
                font-weight: 600;
            }

            &-sales{
                width: 100%;
                padding: 7px 10px;
                border-radius: 6px;
                color: #344054;
                border: 1px solid #D0D5DD;
                font-weight: 600;
                background: white;
            }

            &-features{
                color: #475467;
                padding: 30px 15px;

                h5{
                    font-size: 1.3em;
                    color: #101828;
                    margin: 0;
                }

                &-item{
                    display: flex;
                    align-items: flex-start;

                    img{
                        width: 20px;
                        margin: 2px 7px 0 0;
                    }
                }

            }

        }
    }

    &_detail{
        &-top{
            width: 100%;
            
            p{
                margin-bottom: 5px;
            }

            &-title{
                display: flex;
                position: relative;

                &-action{
                    margin-left: auto;
                    display: flex;
                    
                    button{
                        padding: 0px 10px 2px;
                        border-radius: 6px;
                        color: #344054;
                        border: 2px solid #D0D5DD;
                        font-weight: 600;
                        height: 35px;
                        background: white;

                        img{
                            width: 18px;
                            margin: -2px 5px 0 0;
                        }
                    }

                    img{
                        margin-top: -3px;
                        height: 40px;
                        width: 37px;
                        margin-left: 10px;
                    }
                }

                &-option{
                    position: absolute;
                    background: white;
                    top: 40px;
                    right: 0px;
                    border-radius: 10px;
                    z-index: 2;
                    width: 220px;
                    overflow: hidden;
                    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
                    
                    p{
                        margin: 0;
                        padding: 7px 15px;
                        cursor: pointer;
                        img{
                            margin: -2px 7px 0 0;
                        }
                    }
                }
            }
        }

        &-data{
            border: 1px solid #ECECEC;
            border-radius: 15px;
            padding: 15px 15px 5px;
            margin: 30px auto 10px;
        }

        &-line{
            margin: 30px 0;
            // border: 1px solid #EAECF0;
            // background-color: #EAECF0;
        }

        &-mix{
            &-title{
                display: flex;
                font-size: 0.9em;
                color: #667085;
                img{
                    margin: -20px 5px 0 0;
                }
            }

            &-items{
                display: flex;
            }

            .norm{
                font-size: 0.9em;
                border: 1px solid #EFF8FF;
                color: #175CD3;
                font-weight: 600;
                border-radius: 25px;
                background: #EFF8FF;
                text-align: center;
                cursor: default;
                padding: 2px 10px;
                display: flex;
                align-items: center;
                margin-right: 10px;

                div{
                    background: #175CD3;
                    width: 5px;
                    margin-right: 4px;
                    height: 5px;
                    font-size: 2em;
                    border-radius: 50%;
                }
            }

            .err{
                font-size: 0.9em;
                border: 1px solid #FFF1F3;
                color: #C01048;
                font-weight: 600;
                cursor: default;
                border-radius: 25px;
                background: #FFF1F3;
                text-align: center;
                padding: 2px 10px;
                display: flex;
                align-items: center;
                margin-right: 10px;

                div{
                    background: #C01048;
                    width: 5px;
                    margin-right: 4px;
                    height: 5px;
                    font-size: 2em;
                    border-radius: 50%;
                }
            }
            
            .good{
                font-size: 0.9em;
                border: 1px solid #ECFDF3;
                color: #027A48;
                font-weight: 600;
                cursor: default;
                border-radius: 25px;
                background: #ECFDF3;
                text-align: center;
                padding: 2px 10px;
                display: flex;
                align-items: center;
                margin-right: 10px;

                div{
                    background: #027A48;
                    width: 5px;
                    margin-right: 4px;
                    height: 5px;
                    font-size: 2em;
                    border-radius: 50%;
                }
            }
        }

        &-versionInfo{
            position: relative;

            &-option{
                position: absolute;
                background: white;
                top: 45px;
                right: 0;
                border-radius: 10px;
                z-index: 2;
                width: 170px;
                overflow: hidden;
                box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
                
                p{
                    margin: 0;
                    padding: 7px 10px;
                    cursor: pointer;
                }

                .text1{
                    color: #344054;
                    span{
                        color: #8C8C8C;
                    }
                    img{
                        margin-top: -2px;
                        float: right;
                    }
                }

                .text2{
                    color: #667085;
                    background-color: #E7EAF1;
                    span{
                        color: #8C8C8C;
                    }
                }
            }
        }

        &-info{
            display: flex;
            button{
                z-index: 2;
                color: white;
                border: 1px solid #0046E6;
                border-radius: 12px 0 12px 0;
                padding: 4px 10px;
                height: 40px;
                font-weight: 600;
                background: #0046E6;
                margin: 0 0 0 auto;
                img{
                    margin: -3px 4px 0 0;
                }
            }
        }

        &-history{
            display: flex;

            .fullTable{
                width: 100%;
            }
            
            &-table{
                border: 1px solid #D6D6D6;
                margin-bottom: 30px;
                &-top{
                    display: flex;
                    color: white;
                    font-weight: 600;
                    font-size: 1em;
                    background: #98A2B3;
                    justify-content: space-between;
                    padding: 0 20px;
                    margin-bottom: 20px;
                    position: relative;

                    p{
                        margin: 10px 0;
                    }
                    .last{
                        font-size: 0.9em;
                        cursor: pointer;
                        text-decoration: underline;
                    }

                    &-option{
                        position: absolute;
                        background: white;
                        top: 35px;
                        right: 15px;
                        border-radius: 10px;
                        z-index: 2;
                        overflow: hidden;
                        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
                        
                        p{
                            margin: 0;
                            padding: 7px 10px;
                            cursor: pointer;
                        }

                        .text1{
                            color: #344054;
                            span{
                                color: #8C8C8C;
                            }
                            img{
                                margin-top: -2px;
                                float: right;
                            }
                        }

                        .text2{
                            color: #667085;
                            background-color: #E7EAF1;
                            span{
                                color: #8C8C8C;
                            }
                        }
                    }
                }

                &-bottom{
                    font-weight: 500;
                }
            }
        }

        &-modal{
            h4{
                margin-top: 15px;
            }
            p{
                margin: 0;
                color: #667085
            }
            .name{
                color: black;
            }

            &-item{
                margin: 0 15px;

            }
        }
    }

    &_import{
        &-box{
            max-width: 500px;
            border-radius: 10px;
            background: #F2F4F7;
            padding: 30px;
            margin: 30px 0 50px;
            @media(max-width: 400px){
                padding: 15px;
            }

            &-csv{
                background: white;
                border-radius: 15px;

                h5{
                    border-bottom: 1px solid #D0D5DD;
                    padding: 20px;
                    @media(max-width: 400px){
                        padding: 15px;
                    }
                }

                .main{
                    padding: 20px;
                    @media(max-width: 400px){
                        padding: 15px;
                    }
                }

                &-form{
                    position: relative;
                    input{
                        width: 100%;
                        border-radius: 5px;
                        border: 1px solid #D0D5DD;
                        padding: 7px 15px;
                        margin-bottom: 10px;
                        cursor: default;
                    }

                    .notSelected{
                        color: #98A2B3;
                    }
                    .selected{
                        text-transform: capitalize;
                    }

                    .down{
                        position: absolute;
                        top: 10px;
                        right: 15px;
                        cursor: pointer;
                    }

                    button{
                        border-radius: 6px;
                        color: white;
                        padding: 7px 10px;
                        width: 100%;
                        font-weight: 600;
                    }

                    .aaa{
                        background: #EAECF0;
                        border: 1px solid #EAECF0;
                    }
                    
                    .bbb{
                        background: #3372FF;
                        border: 1px solid #3372FF;
                    }

                    &-options{
                        position: absolute;
                        top: 50px;
                        width: 100%;
                        background: white;
                        border: 1px solid #F2F4F7;
                        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
                        border-radius: 5px;
                        padding: 10px;
                        cursor: pointer;

                        div{
                            display: flex;
                            align-items: center;
                            padding: 5px 0;
                            .img1{
                                margin-right: 10px;
                            }
                            .img2{
                                margin-left: auto;
                            }
                            p{
                                margin: 0;
                            }
                        }
                    }
                }

                &-upload{
                    border: 1px dashed #6695FF;
                    padding: 30px 20px;
                    text-align: center;
                    margin: 30px 0;

                    p{
                        font-weight: 600;
                        color: $color-primary;
                        cursor: pointer;

                        span{
                            color: #475467;
                            cursor: default;
                            font-weight: 400;
                        }
                    }
                }

                &-progress{
                    display: flex;
                    align-items: flex-start;
                    border-radius: 12px;
                    padding: 15px;
                    border: 1px solid $color-primary;

                    .file{
                        width: 30px;
                        margin-right: 10px;
                    }
                    p{
                        color: #344054;
                        margin: 0;
                        font-size: 0.95em;
                    }
                    div{
                        width: 100%;
                    }
                    .mark{
                        float: right;
                    }

                    progress {
                        border-radius: 7px; 
                        width: 100%;
                        height: 8px;
                        margin: 8px 5px 0 0;
                        box-sizing: border-box;
                      }
                      progress::-webkit-progress-bar {
                        background-color: white;
                        border: 0.5px solid $color-primary;
                        border-radius: 7px;
                      }
                      progress::-webkit-progress-value {
                        background-color: $color-primary;
                        border-radius: 7px;
                      }
                      progress::-moz-progress-bar {
                        /* style rules */
                      }
                }

            }
        }

        &-tableBox{
            position: relative;

            &-topLayer{
                height: 38px;
                background: #F2F2F2;
                position: absolute;
                width: 100%;
            }
        }
    }

    &_pagination{
        display: flex;
        justify-content: space-between;
        margin: 20px 15px;
        align-items: center;

        p{
            margin: 0px;
            padding: 5px 10px;
        }

        .active{
            background-color:  #D0D5DD;
            border-radius: 5px;
        }

        button{
            color: #344054;
            border: 2px solid #D0D5DD;
            border-radius: 5px;
            padding: 7px 15px;
            font-weight: 600;
            background: white;

            img{
                width: 20px;
                margin-top: -2px;
            }
        }

        &-prev{
            img{
            }
        }

        &-next{
            img{
                transform: rotate(180deg);
            }
        }
    }
}