.home{
    padding-top: 20px;

    .modal-body{
        height: 80vh;
        overflow-y: auto;
    }
    
    &_main{
        &-topText{
            width: 100%;
            h5{
                font-size: 1.2em;
                font-weight: 700;
                text-transform: capitalize;
            }
            
            p{
                font-size: 1em;
            }

            &-search {
                display: flex;
                justify-content: space-between;
                /* align-items: center; */
                flex-direction: column;
            }

            &-toggles{
                display: flex;
                margin-left: auto;

                &-withSearch{
                    @media(max-width: $mobile){
                        display: block !important;
                    }
                }
            }

            &-filter{
                border: 1px solid #D0D5DD;
                height: 40px;
                border-radius: 10px;
                padding: 6px 10px 0;
                margin-left: 10px;
                min-width: 100px;
                text-transform: capitalize;
                cursor: pointer;
                
                @media(max-width: $mobile){
                    display: none;
                }

                img{
                    margin:-2px 5px 0 0;
                }

                &-options{
                    position: absolute;
                    top: 65px;
                    right: 10px;
                    min-width: 100px;
                    z-index: 2;
                    background: white;
                    border: 1px solid #EAECF0;
                    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
                    p{
                        margin: 5px 10px;
                        cursor: pointer;
                        white-space: nowrap;
                    }
                    hr{
                        margin: 0;
                        border: 1px solid #EAECF0;
                    }
                }
            }

            &-searchDiv {
                margin-left: auto;
                display: flex;    
                width: 230px;
                height: 40px;
                padding: 0.5em;
                align-items: center;
                background: #FFFFFF;
                border: 1px solid #D0D5DD;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                box-sizing: border-box;
                border-radius: 10px;

                @media(max-width: $tab-port){
                    margin: 0 0px 0 auto;
                }
                @media(max-width: $mobile){
                    margin: 15px 0 0;
                    width: 100%;
                }

                input{
                    flex: 1;
                    background: transparent;
                    border: none;
                    outline: none;
                    padding-left: 0.5em;
                    font-weight: 400;
                    font-size: 12.64px;
                    line-height: 16px;
                    color: #06152B;
                    opacity: 0.5;
                }
            }
             
        }

        &-csv{
            display: flex;
            justify-content: flex-end;
            margin: -58px 10px 0 0;
            
            a{
              background: #F5F5F5;
              width: 100px;
              text-align: center;
              border: 1px solid #0046E6;
              color: #000000;
              padding: 5px 18px;
              box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
              border-radius: 8px;
              text-decoration: none;
              background: none;

              img{
                  margin-right: 5px;
              }
            }
        }
    }
}