.settings{
    &_navMain{
        border-bottom: 1px solid #B5B5B5;
        display: flex;
        padding: 0 30px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            width: 0; 
            display: none;
        }
        @media(max-width: $tab-port){
            padding: 0 10px;
        }

        p{
            margin: 0 10px;
            white-space: nowrap;
            padding: 20px 10px 10px;
            cursor: pointer;
            color: black;
            @media(max-width: $tab-port){
                padding: 10px 5px 7px;
                margin: 0 10px;
            }
        }

        .active{
            border-bottom: 3px solid $color-sidebar;
        }
    }

    &_subNav{
        border-bottom: 1px solid #B5B5B5;
        display: flex;
        padding: 10px 30px 0 10px;
        margin-left: 30px;
        @media(max-width: $tab-port){
            margin-left: 0px;
        }

        p{
            margin: 0 20px;
            color: black;
            padding: 20px 10px 10px;
            cursor: pointer;
            @media(max-width: $tab-port){
                padding: 10px 5px 7px;
                font-size: 0.9em;
                margin: 0 10px;
            }
        }
        
        .active{
            border-bottom: 3px solid $color-sidebar;
        }
    }

    &_members{
        box-sizing: border-box;
        // border: 1px solid red;
        
        &-table{
            border: 0.25px solid #0046E6;
            box-sizing: border-box;
            box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
            border-radius: 10px;
            width: 90%;
            padding: 15px 0px;
            margin: 50px 20px;
            @media(max-width: $tab-land){
                margin: 50px 20px 50px 0;
                width: 100%;
                overflow-x: scroll;
            }
            
            @media(max-width: $tab-port){
                margin: 50px 20px 50px -10px;
            }
            @media(max-width: $mobile){
                margin: 50px 20px 50px -5px;
                max-width: 90% !important;
            }

            &-data{
                @media(max-width: $tab-land){
                    min-width: 950px;
                }
            }

            &-top{
                display: flex;
                margin: 0 25px;

                h3{
                    font-weight: 700;
                }

                &-btn{
                    margin-left: auto;
                    display: flex;

                    &-form{
                        width: 270px;
                        position: relative;
                        padding: 0 !important;
                        margin: 0 15px 0 0;

                        input{
                            width: 100%;
                            height: 30px;
                            background: #F5F5F5;
                            border: 1px solid #DADADA;
                            box-sizing: border-box;
                            border-radius: 4px;
                            padding: 5px 10px 5px 30px;
                            font-size: 0.9em;
                            color: #797979;
                        }

                        img{
                            position: absolute;
                            top: 8px;
                            left: 6px;
                            width: 15px;
                        }
                    }

                    button{
                        height: 30px;
                        border: 1px solid $color-sidebar;
                        color: white;
                        background: $color-sidebar;
                        width: 200px;
                        padding: 0px 15px;
                        font-size: 0.9em;
                        border-radius: 6px;
                    }

                    &-btnInactive{
                        border: 1px solid $color-formInput !important;
                        background: $color-formInput !important;
                    }
                }
            }

            &-main{
                padding: 0 20px;

                &-name{
                    .me{
                        color: $color-online;
                        margin-left: 10px;
                        font-size: 0.8em;
                    }
                    .mail{
                        color: #0F123F8F;
                    }
                }

                &-action{
                    button{
                        border: 1px solid #DADADA;
                        border-radius: 4px;
                        padding: 5px;
                        margin-right: 10px;
                        background-color: white;
                        color: #797979;
                    }
                }

                &-status{
                    color: $color-online;
                }
                &-pending{
                    color: #FFC702;
                }
            }

            table{
                width: 100%;
        
                tr{
                    border-bottom: 0.75px solid #B5B5B5;
                    color: #0F123F;
                    font-size: 1em;
                }
                th, td{
                    padding: 10px 0;
                }
                th{
                    opacity: 0.56;
                    font-weight: 400;
                    text-transform: capitalize !important;
                }
            }
        }
    }

    &_roles{
        margin: 0 30px;

        &-default{
            font-weight: 700;
            margin-left: 20px;
        }

        &-item{
            display: flex;
            margin: 0 20px;

            &-icon{
                width: 50px;
                height: 50px;
                overflow: hidden;
                margin-right: 10px;
                border-radius: 50%;

                img{
                    width: 20px;
                    margin: 14px 0 0 14px;
                }
            }

            &-text{
                padding-right: 15px;
                .title{
                    color: $color-textMain;
                    margin: 0;
                }
                .desc{
                    color: #797979;
                    margin: 0 0 5px 0;

                    span{
                        color: #0046E6;
                        cursor: pointer;
                        font-weight: 600;
                    }

                    .spanInactive{
                        color: $color-formInput !important;
                    }
                }
            }

            &-actions{
                display: flex;
                margin-left: auto;
                .view{
                    width: 150px !important;
                }
                .edit{
                    width: 80px !important;
                    margin: 0 7px;
                }
            }

            button{
                margin-left: auto;
                border: 1px solid #DADADA;
                box-sizing: border-box;
                border-radius: 4px;
                background: white;
                height: 35px;
                font-size: 0.9em;
                color: #797979;
                padding: 4px 15px;
            }
        }

        &-notifs{
            margin-left: auto;
            /* Style for toggle button to switch betwen live and test */
            .knobs,
            .layer {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            .button {
                position: relative;
                width: 60px;
                height: 28px;
                /* margin: -20px auto 0 auto; */
                overflow: hidden;
            }

            .button.r,
            .button.r .layer {
                border-radius: 100px;
            }

            .checkbox {
                position: relative;
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;
                opacity: 0;
                cursor: pointer;
                z-index: 3;
            }

            .knobs {
                z-index: 2;
            }

            .layer {
                width: 100%;
                background-color: #CDCDCD;
                transition: 0.3s ease all;
                z-index: 1;
            }

            .button-2 .knobs:before {
                content: "h";
                position: absolute;
                top: 4.5px;
                left: 4px;
                width: 20px;
                height: 8px;
                color: #fff;
                font-size: 10px;
                font-weight: bold;
                text-align: center;
                line-height: 1;
                padding: 9px 4px;
                background-color: white;
                border-radius: 50%;
                transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
            }

            .button-2 .checkbox:checked + .knobs:before {
            content: "";
                left: 36px;
                background-color: white;
            }

            .button-2 .checkbox:checked ~ .layer {
            background-color: #2BBD35;
            }

            .button-2 .knobs,
            .button-2 .knobs:before,
            .button-2 .layer {
            transition: 0.3s ease all;
            }
        }
    }

    &_notifs{
        @media(max-width: 580px){
            margin: 50px -25px 0 10px !important ;
        }
    }

    &_profile{
        &-hookText{
            margin-top: 50px;
            img{
                margin: -2px 5px 0 0;
            }
        }

        &-form{
            box-sizing: border-box;
            
            label{
                margin-top: 10px;
            }

            &-btn1{
                width: 180px !important;
                border: 1px solid #DADADA !important;
                color: #2E2E2E !important;
                font-size: 0.9em;
                background-color: white !important;
                margin: 5px 0 0 !important;
            }

            &-btn2{
                width: 180px !important;
                border: 1px solid $color-formInput !important;
                color: white !important;
                font-size: 0.9em;
                background-color: $color-formInput !important;
                margin: 5px 0 0 !important;
            }
            
            &-btn3{
                width: 90px;
                margin: 0;
                // text-align: right;
                float: right;
            }
            
        }
    }

    &-invite{
        &-mail{
            padding: 30px 0px 40px;
            box-sizing: border-box;
            // background-color: white;
            border-radius: 0 0 10px 10px;

            .mail{
                padding: 7px 15px;
            }
            label{
                margin: 0 0 10px;
            }
            input{
                width: 100%;
                border: 1px solid #DADADA;
                border-radius: 4px;
            }
        }

        &-role{
            border-top: 1px solid #D2D2D2;

            .text1{
                margin: 20px 0 0;
            }

            &-opts{
                margin: 20px 0 0;
                display: flex;

                img{
                    margin: -10px 10px 0 auto;
                }
            }

            &-permissions{
                box-sizing: border-box;
                margin: -10px 0;

                p{
                    font-size: 0.85em;
                    margin: 0;
                }
                &-views{
                    border: 1px solid #A6A6A6;
                    border-radius: 10px;
                    // display: flex;
                    padding: 10px;
                    margin: 0 0 20px;
                    min-height: 70px;

                    div{
                        margin-left: auto;
                        display: flex;
                        flex-direction: column;
                    }
                }
            }

            &-contain{
                position:relative; 
                margin-top:20px; 
                border-bottom:1px solid #D2D2D2; 
                box-sizing:border-box;

                &-views{
                    padding: 10px;
                    margin: 0 0 20px;
                    p{
                        margin: 0;
                    }
                }
            }
        }

        &-action {
            display: flex;
            justify-content: space-around;
            margin: 20px 0;
            .cancel {
                background: white;
                border: 1px solid #7D7D7D;
                padding: 5px 20px;
                border-radius: 7px;
                color: #7D7D7D;
            }

            .publish {
                background: #0046E6;
                  border: 1px solid #0046E6;
                  padding: 5px 20px;
                  border-radius: 7px;
                  color: white;
                  margin-left: auto;
            }
            
            .delete {
                background: #D92D20;
                  border: 1px solid #D92D20;
                  padding: 5px 20px;
                  border-radius: 7px;
                  color: white;
                  margin-left: auto;
            }

            .disabled {
                background: #D0D0D0;
                  border: 1px solid #D0D0D0;
                  padding: 5px 20px;
                  border-radius: 7px;
                  color: white;
                  margin-left: auto;
            }
        }
    }

    &_modals{
        &-threshold{
           padding: 30px 20px; 
           box-sizing: border-box;
           h5{
               margin-top: 15px;
           }
           p{
               color: #667085;
           }
           input{
               border-radius: 7px;
               width: 100%;
               border: 1px solid #D0D5DD;
               padding: 7px 15px;
           }

           .btn1{
                background: white;
                border: 1px solid #D0D5DD;
                padding: 5px 20px;
                border-radius: 7px;
                color: #344054;
                margin-top: 20px;
                // margin-left: auto;
            }
           .btn2{
                background: #0046E6;
                border: 1px solid #0046E6;
                padding: 5px 20px;
                border-radius: 7px;
                color: white;
                margin-top: 20px;
                float: right;
           }
           .btn3{
                background: #D0D0D0;
                border: 1px solid #D0D0D0;
                padding: 5px 20px;
                border-radius: 7px;
                color: white;
                margin-top: 20px;
                float: right;
           }
        }
    }

    &_hook{
        @media(max-width: 460px){
            width: 88%;
            margin: 50px -25px 0 10px !important ;
        }
    }
}


