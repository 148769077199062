*,
*::after,
*::before {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit;
}

html {
  overflow-x: hidden;
}

.u-padding-none {
  padding: 0;
}

.u-margin-none {
  margin: 0;
}

.compliance {
  margin-top: 30px;
}
.compliance_top {
  display: flex;
  margin: auto;
  justify-content: center;
}
.compliance_top-circle {
  background: linear-gradient(128.58deg, #0FCD16 14.67%, #2BBD35 86.8%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 30px;
}
@media (max-width: 560px) {
  .compliance_top-circle {
    display: none;
  }
}
.compliance_top-progress {
  border-top: 2px dashed #629C66;
  width: 300px;
  position: relative;
  margin-top: 20px;
}
@media (max-width: 560px) {
  .compliance_top-progress {
    width: 75%;
    margin: 20px auto;
  }
}
.compliance_top-progress img {
  width: 20px;
  position: absolute;
  top: -12px;
  cursor: pointer;
  background-color: white;
}
.compliance_top-progress p {
  position: absolute;
  font-size: 1em;
  color: #5A5A5A;
  top: 10px;
}
@media (max-width: 560px) {
  .compliance_top-progress p {
    font-size: 0.9em;
  }
}
.compliance_top-progress .icon1 {
  left: 0;
}
.compliance_top-progress .icon2 {
  left: 140px;
}
@media (max-width: 560px) {
  .compliance_top-progress .icon2 {
    left: calc(50% - 10px);
  }
}
.compliance_top-progress .icon3 {
  right: 0;
}
.compliance_top-progress .text1 {
  left: -10px;
}
.compliance_top-progress .text2 {
  left: 120px;
}
@media (max-width: 560px) {
  .compliance_top-progress .text2 {
    left: calc(50% - 30px);
  }
}
.compliance_top-progress .text3 {
  right: -40px;
}
@media (max-width: 560px) {
  .compliance_top-progress .text3 {
    right: -30px;
  }
}
.compliance_main {
  margin: 40px auto 0;
  display: block;
  padding: 20px;
  max-width: 500px;
  border-radius: 10px;
}
.compliance_save {
  width: 540px;
  padding: 10px 0;
  text-align: center;
  display: block;
  margin: 20px auto 30px;
  border-radius: 7px;
  border: 1px solid #0046E6;
  background: #0046E6;
  font-size: 1.1em;
  font-weight: 600;
  color: white;
}
@media (max-width: 560px) {
  .compliance_save {
    width: 100%;
  }
}
.compliance_profile-form {
  box-sizing: border-box;
}
.compliance_profile-form label {
  font-weight: 600;
  font-family: "Mulish", sans-serif;
  margin: 20px 0 5px;
}
.compliance_profile-form p, .compliance_profile-form label {
  color: #353535;
  font-size: 1em;
}
.compliance_profile-form input {
  width: 100%;
  height: 2.5em;
  padding: 5px 15px;
  border: 2px solid #DADADA;
  box-sizing: border-box;
  border-radius: 4px;
}
.compliance_profile-form input:focus {
  border: 2px solid #0046E6;
  outline: none;
}
.compliance_profile-info h6 {
  color: #5B5B5B;
  margin: 0 !important;
  font-size: 1em;
}
.compliance_profile-info p {
  color: #353535;
  font-size: 1em;
}
.compliance_profile-info button {
  background: #EAEAEA;
  border: 1px solid #696969;
  border-radius: 8px;
  text-align: center;
  padding: 8px 15px;
  width: 50px;
  font-weight: 700;
}
.compliance_profile-info-next {
  float: right;
}
.compliance_alldone {
  margin: 20px auto;
  text-align: center;
}

.home {
  padding-top: 20px;
}
.home .modal-body {
  height: 80vh;
  overflow-y: auto;
}
.home_main-topText {
  width: 100%;
}
.home_main-topText h5 {
  font-size: 1.2em;
  font-weight: 700;
  text-transform: capitalize;
}
.home_main-topText p {
  font-size: 1em;
}
.home_main-topText-search {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  flex-direction: column;
}
.home_main-topText-toggles {
  display: flex;
  margin-left: auto;
}
@media (max-width: 560px) {
  .home_main-topText-toggles-withSearch {
    display: block !important;
  }
}
.home_main-topText-filter {
  border: 1px solid #D0D5DD;
  height: 40px;
  border-radius: 10px;
  padding: 6px 10px 0;
  margin-left: 10px;
  min-width: 100px;
  text-transform: capitalize;
  cursor: pointer;
}
@media (max-width: 560px) {
  .home_main-topText-filter {
    display: none;
  }
}
.home_main-topText-filter img {
  margin: -2px 5px 0 0;
}
.home_main-topText-filter-options {
  position: absolute;
  top: 65px;
  right: 10px;
  min-width: 100px;
  z-index: 2;
  background: white;
  border: 1px solid #EAECF0;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}
.home_main-topText-filter-options p {
  margin: 5px 10px;
  cursor: pointer;
  white-space: nowrap;
}
.home_main-topText-filter-options hr {
  margin: 0;
  border: 1px solid #EAECF0;
}
.home_main-topText-searchDiv {
  margin-left: auto;
  display: flex;
  width: 230px;
  height: 40px;
  padding: 0.5em;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  box-sizing: border-box;
  border-radius: 10px;
}
@media (max-width: 769px) {
  .home_main-topText-searchDiv {
    margin: 0 0px 0 auto;
  }
}
@media (max-width: 560px) {
  .home_main-topText-searchDiv {
    margin: 15px 0 0;
    width: 100%;
  }
}
.home_main-topText-searchDiv input {
  flex: 1;
  background: transparent;
  border: none;
  outline: none;
  padding-left: 0.5em;
  font-weight: 400;
  font-size: 12.64px;
  line-height: 16px;
  color: #06152B;
  opacity: 0.5;
}
.home_main-csv {
  display: flex;
  justify-content: flex-end;
  margin: -58px 10px 0 0;
}
.home_main-csv a {
  background: #F5F5F5;
  width: 100px;
  text-align: center;
  border: 1px solid #0046E6;
  color: #000000;
  padding: 5px 18px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  text-decoration: none;
  background: none;
}
.home_main-csv a img {
  margin-right: 5px;
}

.myModal_blurBg {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: -50px;
  bottom: -50px;
  z-index: 3;
  background: rgba(183, 233, 246, 0.25);
  -webkit-backdrop-filter: blur(16px);
          backdrop-filter: blur(16px);
}
.myModal_main {
  max-width: 400px;
  border: 1px solid #0046E6;
  background: white;
  border-radius: 10px;
  z-index: 3;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 560px) {
  .myModal_main {
    width: 300px;
  }
}
.myModal_main_privacy {
  max-width: 400px;
  max-height: 600px;
  border: 1px solid #0046E6;
  background: white;
  border-radius: 10px;
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  margin: 10px;
  overflow-y: scroll;
}
.myModal_main_privacy .top {
  display: flex;
  justify-content: space-between;
}
.myModal_main_privacy .top h3 {
  padding: 20px;
}
.myModal_main_privacy .top h1 {
  font-weight: 600;
  font-size: 17.9141px;
  line-height: 31px;
  align-items: center;
  color: #00072B;
}
.myModal_main_privacy table {
  width: 350px !important;
  text-align: center;
}
.myModal_main-widget-header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.myModal_main-widget-header p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  margin: 0 20px 0 0;
  /* flex: 1.5; */
  text-align: start;
  width: 60%;
}
.myModal_main-widget-upload {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  /* background: #E3E4E7; */
  padding: 4px 20px 4px 10px;
  width: 100%;
  margin: 30px 0;
  border-radius: 8px;
  border: 1px solid #E3E4E7;
}
.myModal_main-widget-upload img {
  width: 30px;
}
.myModal_main-widget-upload p {
  margin: 3px 0 0 10px;
}
.myModal_main-widget-share {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
}
.myModal_main-widget-share img {
  width: 75px;
  height: 75px;
  margin-bottom: 20px;
}
@media (max-width: 560px) {
  .myModal_main-widget-share img {
    width: 75px;
    height: 75px;
    margin-bottom: 10px;
  }
}
.myModal_main-widget-share h4 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 35px;
}
@media (max-width: 560px) {
  .myModal_main-widget-share h4 {
    font-size: 20px;
    line-height: 28px;
  }
}
.myModal_main-widget-share p {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}
@media (max-width: 560px) {
  .myModal_main-widget-share p {
    font-size: 12px;
    line-height: 20px;
  }
}
.myModal_main-widget-share-action {
  display: flex;
  justify-content: space-around;
}
.myModal_main-widget-share-action .cancel {
  background: #D0D0D0;
  border: 1px solid #D0D0D0;
  padding: 5px 20px;
  border-radius: 7px;
  color: white;
  margin-left: auto;
}
.myModal_main-widget-share-action .share {
  background: #0046E6;
  border: 1px solid #0046E6;
  padding: 5px 20px;
  border-radius: 7px;
  color: white;
}
.myModal_main-widget-share-action .decline {
  background: #D0D0D0;
  border: 1px solid #D0D0D0;
  padding: 5px 20px;
  border-radius: 7px;
  color: white;
}
.myModal_main-widget-share-action .goBack {
  background: white;
  border: 1px solid #D0D5DD;
  padding: 5px 20px;
  border-radius: 7px;
  color: #344054;
}
.myModal_main-widget-share-kyc {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.myModal_main-widget-share-kyc button {
  background: #0046E6;
  border: 1px solid #0046E6;
  margin-left: auto;
  width: 100%;
  color: #fff;
  padding: 5px;
  border-radius: 7px;
}
.myModal_main-widget-share-kyc p {
  font-size: 16px;
  margin: 16px 0;
  color: #0046E6;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.5px;
  cursor: pointer;
}
@media (max-width: 560px) {
  .myModal_main-widget-share-kyc p {
    font-size: 12px;
    margin: 12px 0;
  }
}
.myModal_main-widget-share-kyc p a {
  text-decoration: none;
}
.myModal_main-widget-share-kyc .link {
  background: #0046E6;
  border: 1px solid #0046E6;
  padding: 8px 24px;
  border-radius: 7px;
  color: white;
  width: 200px !important;
  font-size: 12px;
}
.myModal_main-widget-share-icons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 15px 0;
}
.myModal_main-widget-share-tick {
  display: flex;
  align-items: center;
  justify-content: center;
}
.myModal_main-widget-share-tick .tick {
  width: 20px;
  height: 20px;
  margin: 0;
}
.myModal_main-widget-share-tick h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  margin: 0 10px;
}
.myModal_main-widget-shareLeft {
  margin: 0;
}
.myModal_main-widget-shareLeft img {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}
@media (max-width: 560px) {
  .myModal_main-widget-shareLeft img {
    width: 75px;
    height: 75px;
    margin-bottom: 10px;
  }
}
.myModal_main-widget-shareLeft h4 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 35px;
}
@media (max-width: 560px) {
  .myModal_main-widget-shareLeft h4 {
    font-size: 20px;
    line-height: 28px;
  }
}
.myModal_main-widget-shareLeft p {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}
@media (max-width: 560px) {
  .myModal_main-widget-shareLeft p {
    font-size: 12px;
    line-height: 20px;
  }
}
.myModal_main-widget-shareLeft .radio [type=radio]:checked,
.myModal_main-widget-shareLeft .radio [type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.myModal_main-widget-shareLeft .radio [type=radio]:checked + label,
.myModal_main-widget-shareLeft .radio [type=radio]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #344054;
  font-weight: 600;
}
.myModal_main-widget-shareLeft .radio [type=radio]:checked + label:before,
.myModal_main-widget-shareLeft .radio [type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #47D800;
  border-radius: 100%;
  background: #fff;
}
.myModal_main-widget-shareLeft .radio [type=radio]:checked + label:after,
.myModal_main-widget-shareLeft .radio [type=radio]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #47D800;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.myModal_main-widget-shareLeft .radio [type=radio]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.myModal_main-widget-shareLeft .radio [type=radio]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.myModal_main-widget-form {
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 30px;
  position: relative;
}
.myModal_main-widget-form input {
  width: 100%;
  margin: 10px 0;
  padding: 5px 15px;
  height: 40px;
  border: 2px solid #C4C4C4;
  border-radius: 5px;
  color: #8A8A8A;
}
.myModal_main-widget-form input:focus {
  border-color: #296AFC !important;
}
.myModal_main-widget-form .type {
  display: flex;
  justify-content: space-evenly;
}
.myModal_main-widget-form .type p {
  font-weight: 600;
  padding: 10px 15px 5px;
  margin: 0 0 5px;
  cursor: pointer;
}
.myModal_main-widget-form .type_active {
  border-bottom: 3px solid #0046E6;
}
.myModal_main-widget-form .bank {
  width: 20px;
  position: absolute;
  bottom: 20px;
  left: 9px;
}
.myModal_main-widget-form .eye {
  width: 20px;
  cursor: pointer;
  position: absolute;
  bottom: 19px;
  right: 10px;
}
.myModal_main-widget-form-text1 {
  margin: 30px 0 0;
  font-weight: 600;
}
.myModal_main-widget-form-dragUpload {
  border: 1px solid #EAECF0;
  border-radius: 8px;
  margin: -15px 0 10px;
  padding: 20px;
  cursor: pointer;
}
.myModal_main-widget-form-dragUpload img {
  margin: auto;
  display: block;
}
.myModal_main-widget-form-dragUpload p {
  text-align: center;
  margin: 0;
}
.myModal_main-widget-form-dragUpload .text1 {
  font-weight: 700;
  color: #0046E6;
}
.myModal_main-widget-form-dragUpload .text2 {
  font-size: 0.85em;
}
.myModal_main-widget-action {
  display: flex;
}
.myModal_main-widget-action .form-submit {
  background: #0046E6;
  border: 1px solid #0046E6;
  margin-left: auto;
  width: 100%;
}
.myModal_main-widget-action .form-disable {
  background: #D0D0D0;
  border: 1px solid #D0D0D0;
  margin-left: auto;
  width: 100%;
}
.myModal_main-widget-action button {
  padding: 5px 20px;
  border-radius: 7px;
  color: white;
}
.myModal_main-widget-action .cancel {
  background: #D0D0D0;
  border: 1px solid #D0D0D0;
}
.myModal_main-widget-action .publish {
  background: #0046E6;
  border: 1px solid #0046E6;
  margin-left: auto;
}
.myModal_main-widget-action .back {
  background: white;
  border: 1px solid #D0D5DD;
  color: #344054;
}
.myModal_main-widget-action .inactiveBtn {
  border: 1px solid #8A8A8A !important;
  background: #8A8A8A !important;
}
.myModal_main-widget-action-close {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.myModal_main-widget-action-close button {
  padding: 5px 20px;
  border-radius: 7px;
  color: white;
}
.myModal_main-widget-action-close .close {
  background: #D0D0D0;
  border: 1px solid #D0D0D0;
  margin: 0 0 20px 0;
}
.myModal_main-linkBank input {
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid #757575;
  color: #757575;
  font-size: 1em;
  width: 100%;
  padding: 5px;
  margin: 30px 0;
}
.myModal_main-linkBank input:focus {
  outline: none;
}
.myModal_main-linkBank-toggle {
  display: flex;
  margin: 20px 0 -30px;
}
.myModal_main-linkBank-toggle-btn {
  margin-left: auto;
}
.myModal_main-linkBank-radio [type=radio]:checked,
.myModal_main-linkBank-radio [type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.myModal_main-linkBank-radio [type=radio]:checked + label,
.myModal_main-linkBank-radio [type=radio]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.myModal_main-linkBank-radio [type=radio]:checked + label:before,
.myModal_main-linkBank-radio [type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #0046E6;
  border-radius: 100%;
  background: #fff;
}
.myModal_main-linkBank-radio [type=radio]:checked + label:after,
.myModal_main-linkBank-radio [type=radio]:not(:checked) + label:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #0046E6;
  position: absolute;
  top: 4.5px;
  left: 4.5px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.myModal_main-linkBank-radio [type=radio]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.myModal_main-linkBank-radio [type=radio]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.myModal_main-shareReport input {
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid #757575;
  color: #757575;
  font-size: 1em;
  width: 100%;
  padding: 5px;
  margin: 0 0 15px;
}
.myModal_main-shareReport input:focus {
  outline: none;
}
.myModal_main_password input {
  width: 100%;
  border: 1px solid #DADADA;
  border-radius: 4px;
  color: #757575;
  font-size: 1em;
  width: 100%;
  padding: 5px;
  margin: 5px 0 15px;
}
.myModal_newshare {
  width: 400px;
  border-radius: 10px;
  z-index: 3;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 560px) {
  .myModal_newshare {
    width: 300px;
  }
}
.myModal_newshare-content-wa {
  padding: 30px 30px 40px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 0 0 10px 10px;
}
.myModal_newshare-content-wa .mail {
  padding: 7px 15px;
}
.myModal_newshare-content-wa label {
  margin: 0 0 10px;
}
.myModal_newshare-content-wa input {
  width: 100%;
  border: 1px solid #DADADA;
  border-radius: 4px;
}
.myModal_newerShare-content-wa {
  padding: 30px 15px 40px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 0 0 10px 10px;
}
.myModal_newerShare-content-wa .mail {
  padding: 7px 15px;
}
.myModal_newerShare-content-wa label {
  margin: 0 0 10px;
}
.myModal_newerShare-content-wa input {
  width: 100%;
  border: 1px solid #DADADA;
  border-radius: 4px;
}
.myModal_newerShare-content-mail .div1 {
  display: flex;
}
.myModal_newerShare-content-mail .btn2 {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
  color: #0046E6;
  padding: 10px 0;
  width: 70px;
  margin-left: 10px;
  text-decoration: underline;
  font-weight: 500;
}
.myModal_newerShare-content-mail .div2 {
  display: flex;
  box-sizing: border-box;
}
.myModal_newerShare-content-mail .div3 {
  background-color: #F4F4F4;
  display: flex;
  padding: 10px 15px;
  width: calc(100% - 110px);
  border-radius: 7px;
  margin-right: 10px;
}
.myModal_newerShare-content-mail .div3 p {
  overflow: hidden;
  margin: 0;
  white-space: nowrap;
}
.myModal_newerShare-content-mail .div3 img {
  margin-left: 10px;
  cursor: pointer;
}
.myModal_income {
  padding: 20px;
}
.myModal_income .filter-cancel {
  position: absolute;
  top: 20px;
  cursor: pointer;
  right: 20px;
}
.myModal_income .text1 {
  margin: 20px 0 7px;
  font-size: 0.9em;
}
.myModal_income-box1-main {
  display: flex;
}
.myModal_income-box1-main-form {
  border: 1px solid #CCD8F3;
  border-radius: 4px;
  padding: 10px 20px;
  box-sizing: border-box;
  width: 100%;
}
.myModal_income-box1-main-form-rangelabel {
  margin: 10px 0 5px;
}
.myModal_income-box1-main-form-range {
  display: flex;
}
.myModal_income-box1-main-form-range div {
  width: 25%;
  cursor: pointer;
  border: 1px solid #D0D5DD;
}
.myModal_income-box1-main-form-range div p {
  margin: 6px 0;
  text-align: center;
}
.myModal_income-box1-main-form-range .active {
  color: white;
  background: #0046E6;
  border: 1px solid #0046E6;
}
.myModal_income-box1-main-form-range .div1 {
  border-radius: 7px 0 0 7px;
}
.myModal_income-box1-main-form-range .div2 {
  border-radius: 0 7px 7px 0;
}
.myModal_income-box1-main-form-amount {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.myModal_income-box1-main-form-amount p {
  font-size: 1.5em;
  margin: 0 10px;
}
.myModal_income-box1-main-form-amount input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #D0D5DD;
  color: #667085;
  padding: 0 10px;
}
.myModal_income-box1-main-delete {
  width: 30px;
  margin-left: 10px;
  cursor: pointer;
}
.myModal_income-box1-btns {
  margin-bottom: 30px;
}
.myModal_income-box1-btns button {
  width: 80px;
  margin: 15px 10px 0 0;
  padding: 6px 0;
  border-radius: 4px;
  border: 1px solid transparent;
}
.myModal_income-box1-btns button img {
  margin-top: -4px;
}
.myModal_income-box1-btns .and {
  color: #006907;
  background: #BED4BF;
}
.myModal_income-box1-btns .or {
  color: #0046E6;
  background: #CFE1F4;
}
.myModal_income-box1-btns .selected {
  color: #535353;
  background: #CDCDCD;
  text-transform: uppercase;
}
.myModal_income-save {
  background: #F4F4F4;
  padding: 30px 20px;
  border-radius: 0 0 7px 7px;
}

.table {
  box-sizing: border-box;
  width: 100%;
  padding: 15px 0px;
  text-transform: capitalize;
  border: 1px solid #EAECF0;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 0 0 8px 8px;
}
@media (max-width: 1400px) {
  .table {
    overflow-x: scroll;
  }
}
.table_top {
  border-radius: 8px 8px 0 0;
  border: 1px solid #EAECF0;
  padding: 20px 10px;
  margin-top: 30px;
  position: relative;
}
.table_top h4 {
  font-size: 1.3em;
  margin: 3px 0 0;
}
.table_top h4 img {
  margin-right: 10px;
  margin-top: -3px;
}
.table_banks {
  position: relative;
  height: 60px;
  width: 120px;
}
.table_banks-box {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: absolute;
  border: 2px solid white;
  overflow: hidden;
}
.table_banks-box img {
  width: 100%;
  height: auto;
}
.table_banks .first {
  top: 5px;
  left: 0;
  background: white;
}
.table_banks .second {
  top: 5px;
  left: 25px;
  background: white;
}
.table_banks .others {
  top: 5px;
  left: 50px;
  background-color: rgb(0, 180, 255);
}
.table_banks .others p {
  font-size: 1.4em;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-top: 5px;
}
.table_action {
  position: relative;
  height: 60px;
  width: 30px;
}
.table_action-contain {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  overflow: hidden;
  background: #0046E6;
}
.table_action-options {
  position: absolute;
  top: 45px;
  right: 5px;
  border-radius: 15px;
  background: white;
  z-index: 2;
  width: 80px;
  padding: 10px;
  box-shadow: 0px 15.1397px 161.94px rgba(37, 72, 153, 0.17);
}
.table_action-options hr {
  margin: 10px 0;
}
.table_action-options p {
  cursor: pointer;
  margin: 0;
}
.table table {
  width: 100%;
}
.table table tr {
  border-bottom: 0.75px solid #EAECF0 !important;
  color: #0F123F;
  font-size: 1em;
}
.table table th, .table table td {
  padding: 10px 5px 10px 10px;
}
.table table th {
  opacity: 0.56;
  font-weight: 400;
  text-transform: capitalize !important;
}
.table table thead {
  border-bottom: 1px solid #EAECF0 !important;
}
.table table tbody {
  border: 1px solid #EAECF0 !important;
}
.table table .open {
  font-size: 0.9em;
  border: 1px solid rgba(196, 32, 92, 0.19);
  color: #D1373F;
  font-weight: 600;
  border-radius: 25px;
  background: rgba(196, 32, 92, 0.19);
  text-align: center;
  padding: 2px 10px;
  cursor: default;
  display: flex;
  align-items: center;
}
.table table .open div {
  background: #D1373F;
  width: 5px;
  margin-right: 4px;
  height: 5px;
  font-size: 2em;
  border-radius: 50%;
}
.table table .closed {
  font-size: 0.9em;
  border: 1px solid #ECFDF3;
  color: #027A48;
  font-weight: 600;
  cursor: default;
  border-radius: 25px;
  background: #ECFDF3;
  text-align: center;
  padding: 2px 10px;
  display: flex;
  align-items: center;
}
.table table .closed div {
  background: #027A48;
  width: 5px;
  margin-right: 4px;
  height: 5px;
  font-size: 2em;
  border-radius: 50%;
}
.table table .inProgress {
  font-size: 0.9em;
  border: 1px solid #ECFDF3;
  color: #D88303;
  font-weight: 600;
  cursor: default;
  border-radius: 25px;
  background: #FEE4BE;
  text-align: center;
  padding: 2px 10px;
  display: flex;
  align-items: center;
}
.table table .inProgress div {
  background: #FEE4BE;
  width: 5px;
  margin-right: 4px;
  height: 5px;
  font-size: 2em;
  border-radius: 50%;
}
.table table .deliquent {
  font-size: 0.9em;
  border: 1px solid #ECFDF3;
  color: #fbf5f6;
  font-weight: 600;
  cursor: default;
  border-radius: 25px;
  background: #D1373F;
  text-align: center;
  padding: 2px 10px;
  display: flex;
  align-items: center;
}
.table table .deliquent div {
  background: #FEE4BE;
  width: 5px;
  margin-right: 4px;
  height: 5px;
  font-size: 2em;
  border-radius: 50%;
}
.table table .other {
  font-size: 0.9em;
  border: 1px solid #FFF3C8;
  color: #FF7B02;
  font-weight: 600;
  cursor: default;
  border-radius: 25px;
  background: #FFF3C8;
  text-align: center;
  padding: 2px 10px;
  display: flex;
  align-items: center;
}
.table table .other div {
  background: #FF7B02;
  width: 5px;
  margin-right: 4px;
  height: 5px;
  font-size: 2em;
  border-radius: 50%;
}
.table_income-btn button {
  font-size: 0.8em;
  padding: 3px 10px;
  color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: left;
}
.table_income-btn button img {
  width: 20px;
}
.table_income-btn .view {
  background-color: #47D800;
  margin-right: 10px;
  width: 100px;
}
.table_income-btn .view img {
  margin-bottom: 3px;
  margin-right: 8px;
}
.table_income-btn .reload {
  background-color: #0046E6;
  width: 125px;
  margin-right: 10px;
}
.table_income-btn .reload img {
  margin-right: 5px;
}
.table_income-btn .share {
  background-color: #0046E6;
}
.table_income-btn .share img {
  margin: -3px 5px 0 0;
  width: 15px;
}
.table_income-btn .link {
  background-color: #47D800;
  margin-right: 10px;
}
.table_income-btn .link img {
  margin-bottom: 3px;
  margin-right: 8px;
}
.table_income-btn .inactive {
  background-color: #8A8A8A !important;
}
.table_audit .green {
  color: #47D800;
}
.table_audit .red {
  color: #FF2608;
}
.table_audit-btn button {
  font-size: 0.9em;
  color: white;
  padding: 4px 10px;
  border-radius: 5px;
  text-transform: capitalize;
}
.table_audit-btn .success {
  background: #47D800;
  border: 1px solid #47D800;
}
.table_audit-btn .failed {
  background: #FF2608;
  border: 1px solid #FF2608;
}
.table_scroll-none {
  overflow: hidden;
}
@media (max-width: 769px) {
  .table_scroll-tabPort table {
    min-width: 800px;
  }
}

.income_actions {
  margin: 40px;
}
.income_actions-box {
  border: 0.25px solid #0046E6;
  box-sizing: border-box;
  box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  width: 300px;
  padding: 15px;
  text-align: center;
  margin: 0 40px 0 0;
}
@media (max-width: 769px) {
  .income_actions-box {
    width: 100%;
    margin-bottom: 30px;
  }
}
.income_actions-radar {
  border: 0.25px solid #0046E6;
  box-sizing: border-box;
  box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  width: 300px;
  padding: 15px;
  margin: 0 40px 0 0;
}
@media (max-width: 769px) {
  .income_actions-radar {
    width: 100%;
    margin-bottom: 30px;
  }
}
.income_actions-radar .comingSoon {
  display: flex;
}
.income_actions-radar .comingSoon p {
  color: white;
  background: #FFC702;
  font-size: 0.75em;
  border-radius: 5px;
  height: 1.4em;
  padding: 0px 2px !important;
  margin: 12px 0 0 7px;
}
.income_actions-radar input {
  width: 100%;
  margin: 10px 0 20px;
  padding: 5px 15px;
  height: 40px;
  border: 2px solid #C4C4C4;
  border-radius: 5px;
  color: #8A8A8A;
}
.income_actions-radar button {
  width: 100%;
}
.income_actions h4 {
  font-size: 1.2em;
  margin: 10px 0 !important;
}
.income_actions button {
  border: 1px solid #0046E6;
  border-radius: 8px;
  background-color: #0046E6;
  padding: 8px 15px;
  color: white;
  margin: 0 0 15px;
  text-align: center;
  font-weight: 500;
}
.income_radarNumber {
  position: relative;
}
.income_radarNumber h4 {
  font-size: 1.2em;
  margin: 10px 0 !important;
}
.income_radarNumber button {
  border: 1px solid #0046E6;
  border-radius: 8px;
  background-color: #0046E6;
  padding: 8px 15px;
  color: white;
  margin: 0 0 15px;
  text-align: center;
  font-weight: 500;
}
.income_radarNumber-cancel {
  position: absolute;
  top: -30px;
  left: 0;
}
.income_radarNumber-form {
  box-sizing: border-box;
  box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  padding: 15px;
}
@media (max-width: 769px) {
  .income_radarNumber-form {
    width: 100%;
    margin-bottom: 30px;
  }
}
.income_radarNumber-form .comingSoon {
  display: flex;
}
.income_radarNumber-form .comingSoon p {
  color: white;
  background: #FFC702;
  font-size: 0.75em;
  border-radius: 5px;
  height: 1.4em;
  padding: 0px 2px !important;
  margin: 12px 0 0 7px;
}
.income_radarNumber-form input {
  width: 100%;
  margin: 10px 0 20px;
  padding: 5px 15px;
  height: 40px;
  border: 2px solid #C4C4C4;
  border-radius: 5px;
  color: #8A8A8A;
}
.income_radarNumber-form button {
  width: 100%;
}
.income_cards-front {
  width: 400px;
  height: 220px;
  color: white;
  border-radius: 15px;
  padding: 20px;
}
.income_cards-front-logo {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
}
.income_cards-front-logo img {
  width: 100%;
}
.income_cards-front-text {
  margin: 0px 10px;
}
.income_cards-front-text p {
  margin: 0 !important;
  font-size: 0.9em;
}
.income_cards-front-text h4 {
  margin: 0 !important;
  font-size: 1.3em;
}
.income_keywords {
  display: flex;
  align-items: flex-start;
}
.income_keywords-info {
  position: relative;
  margin: -5px 5px 0 0;
}
.income_keywords-info img {
  cursor: pointer;
}
.income_keywords-info-content {
  padding: 15px;
  background: white;
  z-index: 2;
  width: 300px;
  font-size: 0.9em;
  text-align: justify;
  right: 15px;
  border-radius: 15px 0 15px 15px;
  position: absolute;
  border: 1px solid #F2F4F7;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}
.income_keywords-info-content p {
  margin: 0;
}
.income_editFilter {
  display: flex;
}
.income_editFilter button {
  padding: 7px 10px;
  background: white;
  border: 1px solid #D0D5DD;
  color: #344054;
  border-radius: 6px;
  margin-right: 10px;
}
.income_editFilter .btnInactive {
  color: white;
  border: 1px solid #8A8A8A;
  background: #8A8A8A !important;
}
.income_editFilter p {
  font-size: 0.8em;
  margin: 0;
}
.income_editFilter_filters {
  background: #EFF8FF;
  border-radius: 10px;
  color: #296AFC;
  padding: 1px 7px;
  display: flex;
  align-items: center;
}
.income_editFilter_filters .dot {
  background: #296AFC;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 3px;
}
.income_editFilter_filters-query {
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 5px;
}
.income_topic {
  display: flex;
  align-items: center;
}
.income_topic h5 {
  margin-bottom: 15px;
  flex-shrink: 0;
}
.income_topic-months {
  background: #F4F3FF;
  border-radius: 10px;
  display: flex;
  width: 100px;
  justify-content: center;
  align-items: center;
  margin: -15px 0 0 10px;
  padding: 0;
}
.income_topic-months div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  background: #5925DC;
}
.income_topic-months p {
  margin: 0;
  color: #5925DC;
  font-weight: 500;
}
.income_spending-box {
  border: 1px solid #D0D5DD;
  padding: 20px;
  width: 500px;
  margin: 30px 0;
  border-radius: 10px;
  box-sizing: border-box;
}
.income_spending-box-filter-options {
  z-index: 2;
  background: white;
  max-height: 280px;
  border: 1px solid #EAECF0;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}
.income_spending-box-filter-options ::-webkit-scrollbar {
  width: 10px;
}
.income_spending-box-filter-options ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}
.income_spending-box-filter-options ::-webkit-scrollbar-thumb {
  background: #EEEFF0;
  border-radius: 10px;
}
.income_spending-box-filter-options ::-webkit-scrollbar-thumb:hover {
  background: #EEEFF0;
}
.income_spending-box-filter-options p {
  margin: 5px 10px;
  cursor: pointer;
  white-space: nowrap;
}
.income_spending-box-filter-options hr {
  margin: 0;
  border: 1px solid #EAECF0;
}
.income_spending-box-filter-monthRange {
  padding: 20px;
}
.income_spending-box-filter-months {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 -10px;
}
.income_spending-box-filter-months p {
  width: 33%;
  margin: 5px 0;
  padding: 5px 0;
  text-align: center;
  font-weight: 600;
  font-size: 0.95em;
  cursor: pointer;
}
.income_spending-box-filter-months .first {
  background: #1B60FC;
  border-radius: 7px 0 0 7px;
  color: white;
}
.income_spending-box-filter-months .middle {
  background: #F2F4F7;
}
.income_spending-box-filter-months .last {
  background: #1B60FC;
  border-radius: 0 7px 7px 0;
  color: white;
}
.income_spending-box-data {
  display: flex;
  align-items: center;
}
.income_spending-box-data .rep {
  margin-left: 40px;
}
.income_spending-box-data .rep div {
  display: flex;
  align-items: center;
}
.income_spending-box-data .rep div p {
  margin: 0;
  text-transform: capitalize;
}
.income_spending-box-data .rep div span {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}
.income_spending-box-single {
  display: flex;
  z-index: 1;
  align-items: flex-start;
}
.income_spending-box-single img {
  width: 40px;
  margin-right: 5px;
  flex-shrink: 0;
}
.income_spending-box-single p {
  font-size: 0.9em;
  text-transform: capitalize;
}
.income_spending-box-single p span {
  font-size: 1.2em;
  color: #5C5C5C;
  font-weight: 600;
}
.income-filterActive {
  border: 1px solid white;
  background: rgb(27, 96, 252);
}
.income-filterActive-text {
  color: white;
}
.income_detailsNav-filter {
  margin: 30px 0 -10px;
  display: flex;
  align-items: center;
}
.income_detailsNav-filter p {
  margin: 0;
}
.income_detailsNav-filter .filterBy {
  color: #4C8BF5;
}
.income_detailsNav-filter .filterOption {
  border-radius: 5px;
  padding: 5px 0;
  text-align: center;
  border: 1px solid #888888;
  color: #888888;
  width: 100px;
  margin-left: 10px;
  cursor: pointer;
}
.income_detailsNav-filter .active {
  border: 1px solid #4C8BF5;
  background-color: #4C8BF5;
  color: white;
}
.income_detailsNav-table {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.income_detailsNav-table-size1 {
  width: 700px;
}
@media (max-width: 769px) {
  .income_detailsNav-table-size1 {
    width: 100%;
  }
}
.income_detailsNav-table-size2 {
  width: 400px;
}
@media (max-width: 769px) {
  .income_detailsNav-table-size2 {
    width: 100%;
  }
}
.income_detailsNav-table-switch {
  width: 50px;
  margin-left: 30px;
  cursor: pointer;
}
@media (max-width: 769px) {
  .income_detailsNav-table-switch {
    display: none;
  }
}
.income_highlight {
  padding: 30px;
  margin: 30px 0;
  max-width: 800px;
  border: 1px solid #D0D5DD;
  border-radius: 10px;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(243, 246, 255, 0.08) 100%);
}
.income_highlight_top {
  border-radius: 8px 8px 0 0;
  border: 1px solid #EAECF0;
  padding: 15px 10px;
}
.income_highlight_top h4 {
  font-size: 1.3em;
  margin: 3px 0 0;
}
.income_highlight_top h4 img {
  margin-right: 10px;
  margin-top: -3px;
}
.income_highlight table {
  width: 100%;
  border: 1px solid #EAECF0;
}
.income_highlight table tr {
  border-bottom: 1px solid #DADADA;
}
.income_highlight table th, .income_highlight table td {
  border-bottom: 1px solid #EAECF0;
  padding: 15px 5px !important;
  margin: 0 5px;
  color: #667085;
}
.income_highlight table th {
  color: #667085;
  font-weight: 400;
  background-color: #F9FAFB;
}
.income_highlight table .first {
  color: black;
  font-weight: 500;
}
.income_highlight table .error {
  color: #FF0000;
}
.income_highlight table .good {
  color: #48D900;
}

.apps_create {
  border: 1px solid #0046E6;
  box-sizing: border-box;
  border-radius: 15px;
  box-sizing: border-box;
  width: 400px;
  padding: 20px;
  margin: 30px 40px;
}
@media (max-width: 560px) {
  .apps_create {
    width: 90%;
    margin: 30px 60px 30px 0px;
  }
}
.apps_create-input {
  width: 100%;
  border: 1px solid #DADADA;
  color: #8A8A8A;
  padding: 5px 15px;
  margin: 10px 0;
  border-radius: 7px;
}
.apps_create-button {
  padding: 5px 20px;
  border-radius: 7px;
  width: 100%;
  margin: 10px 0 20px;
  color: white;
  background: #0046E6;
  border: 1px solid #0046E6;
}
.apps_create-btnInactive {
  padding: 5px 20px;
  border-radius: 7px;
  width: 100%;
  margin: 10px 0 20px;
  color: white;
  background: #8A8A8A;
  border: 1px solid #8A8A8A;
}

.settings_navMain {
  border-bottom: 1px solid #B5B5B5;
  display: flex;
  padding: 0 30px;
  overflow-x: scroll;
}
.settings_navMain::-webkit-scrollbar {
  width: 0;
  display: none;
}
@media (max-width: 769px) {
  .settings_navMain {
    padding: 0 10px;
  }
}
.settings_navMain p {
  margin: 0 10px;
  white-space: nowrap;
  padding: 20px 10px 10px;
  cursor: pointer;
  color: black;
}
@media (max-width: 769px) {
  .settings_navMain p {
    padding: 10px 5px 7px;
    margin: 0 10px;
  }
}
.settings_navMain .active {
  border-bottom: 3px solid #0046E6;
}
.settings_subNav {
  border-bottom: 1px solid #B5B5B5;
  display: flex;
  padding: 10px 30px 0 10px;
  margin-left: 30px;
}
@media (max-width: 769px) {
  .settings_subNav {
    margin-left: 0px;
  }
}
.settings_subNav p {
  margin: 0 20px;
  color: black;
  padding: 20px 10px 10px;
  cursor: pointer;
}
@media (max-width: 769px) {
  .settings_subNav p {
    padding: 10px 5px 7px;
    font-size: 0.9em;
    margin: 0 10px;
  }
}
.settings_subNav .active {
  border-bottom: 3px solid #0046E6;
}
.settings_members {
  box-sizing: border-box;
}
.settings_members-table {
  border: 0.25px solid #0046E6;
  box-sizing: border-box;
  box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  width: 90%;
  padding: 15px 0px;
  margin: 50px 20px;
}
@media (max-width: 992px) {
  .settings_members-table {
    margin: 50px 20px 50px 0;
    width: 100%;
    overflow-x: scroll;
  }
}
@media (max-width: 769px) {
  .settings_members-table {
    margin: 50px 20px 50px -10px;
  }
}
@media (max-width: 560px) {
  .settings_members-table {
    margin: 50px 20px 50px -5px;
    max-width: 90% !important;
  }
}
@media (max-width: 992px) {
  .settings_members-table-data {
    min-width: 950px;
  }
}
.settings_members-table-top {
  display: flex;
  margin: 0 25px;
}
.settings_members-table-top h3 {
  font-weight: 700;
}
.settings_members-table-top-btn {
  margin-left: auto;
  display: flex;
}
.settings_members-table-top-btn-form {
  width: 270px;
  position: relative;
  padding: 0 !important;
  margin: 0 15px 0 0;
}
.settings_members-table-top-btn-form input {
  width: 100%;
  height: 30px;
  background: #F5F5F5;
  border: 1px solid #DADADA;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 10px 5px 30px;
  font-size: 0.9em;
  color: #797979;
}
.settings_members-table-top-btn-form img {
  position: absolute;
  top: 8px;
  left: 6px;
  width: 15px;
}
.settings_members-table-top-btn button {
  height: 30px;
  border: 1px solid #0046E6;
  color: white;
  background: #0046E6;
  width: 200px;
  padding: 0px 15px;
  font-size: 0.9em;
  border-radius: 6px;
}
.settings_members-table-top-btn-btnInactive {
  border: 1px solid #8A8A8A !important;
  background: #8A8A8A !important;
}
.settings_members-table-main {
  padding: 0 20px;
}
.settings_members-table-main-name .me {
  color: #087F00;
  margin-left: 10px;
  font-size: 0.8em;
}
.settings_members-table-main-name .mail {
  color: rgba(15, 18, 63, 0.5607843137);
}
.settings_members-table-main-action button {
  border: 1px solid #DADADA;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  background-color: white;
  color: #797979;
}
.settings_members-table-main-status {
  color: #087F00;
}
.settings_members-table-main-pending {
  color: #FFC702;
}
.settings_members-table table {
  width: 100%;
}
.settings_members-table table tr {
  border-bottom: 0.75px solid #B5B5B5;
  color: #0F123F;
  font-size: 1em;
}
.settings_members-table table th, .settings_members-table table td {
  padding: 10px 0;
}
.settings_members-table table th {
  opacity: 0.56;
  font-weight: 400;
  text-transform: capitalize !important;
}
.settings_roles {
  margin: 0 30px;
}
.settings_roles-default {
  font-weight: 700;
  margin-left: 20px;
}
.settings_roles-item {
  display: flex;
  margin: 0 20px;
}
.settings_roles-item-icon {
  width: 50px;
  height: 50px;
  overflow: hidden;
  margin-right: 10px;
  border-radius: 50%;
}
.settings_roles-item-icon img {
  width: 20px;
  margin: 14px 0 0 14px;
}
.settings_roles-item-text {
  padding-right: 15px;
}
.settings_roles-item-text .title {
  color: #0F123F;
  margin: 0;
}
.settings_roles-item-text .desc {
  color: #797979;
  margin: 0 0 5px 0;
}
.settings_roles-item-text .desc span {
  color: #0046E6;
  cursor: pointer;
  font-weight: 600;
}
.settings_roles-item-text .desc .spanInactive {
  color: #8A8A8A !important;
}
.settings_roles-item-actions {
  display: flex;
  margin-left: auto;
}
.settings_roles-item-actions .view {
  width: 150px !important;
}
.settings_roles-item-actions .edit {
  width: 80px !important;
  margin: 0 7px;
}
.settings_roles-item button {
  margin-left: auto;
  border: 1px solid #DADADA;
  box-sizing: border-box;
  border-radius: 4px;
  background: white;
  height: 35px;
  font-size: 0.9em;
  color: #797979;
  padding: 4px 15px;
}
.settings_roles-notifs {
  margin-left: auto;
  /* Style for toggle button to switch betwen live and test */
}
.settings_roles-notifs .knobs,
.settings_roles-notifs .layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.settings_roles-notifs .button {
  position: relative;
  width: 60px;
  height: 28px;
  /* margin: -20px auto 0 auto; */
  overflow: hidden;
}
.settings_roles-notifs .button.r,
.settings_roles-notifs .button.r .layer {
  border-radius: 100px;
}
.settings_roles-notifs .checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}
.settings_roles-notifs .knobs {
  z-index: 2;
}
.settings_roles-notifs .layer {
  width: 100%;
  background-color: #CDCDCD;
  transition: 0.3s ease all;
  z-index: 1;
}
.settings_roles-notifs .button-2 .knobs:before {
  content: "h";
  position: absolute;
  top: 4.5px;
  left: 4px;
  width: 20px;
  height: 8px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}
.settings_roles-notifs .button-2 .checkbox:checked + .knobs:before {
  content: "";
  left: 36px;
  background-color: white;
}
.settings_roles-notifs .button-2 .checkbox:checked ~ .layer {
  background-color: #2BBD35;
}
.settings_roles-notifs .button-2 .knobs,
.settings_roles-notifs .button-2 .knobs:before,
.settings_roles-notifs .button-2 .layer {
  transition: 0.3s ease all;
}
@media (max-width: 580px) {
  .settings_notifs {
    margin: 50px -25px 0 10px !important;
  }
}
.settings_profile-hookText {
  margin-top: 50px;
}
.settings_profile-hookText img {
  margin: -2px 5px 0 0;
}
.settings_profile-form {
  box-sizing: border-box;
}
.settings_profile-form label {
  margin-top: 10px;
}
.settings_profile-form-btn1 {
  width: 180px !important;
  border: 1px solid #DADADA !important;
  color: #2E2E2E !important;
  font-size: 0.9em;
  background-color: white !important;
  margin: 5px 0 0 !important;
}
.settings_profile-form-btn2 {
  width: 180px !important;
  border: 1px solid #8A8A8A !important;
  color: white !important;
  font-size: 0.9em;
  background-color: #8A8A8A !important;
  margin: 5px 0 0 !important;
}
.settings_profile-form-btn3 {
  width: 90px;
  margin: 0;
  float: right;
}
.settings-invite-mail {
  padding: 30px 0px 40px;
  box-sizing: border-box;
  border-radius: 0 0 10px 10px;
}
.settings-invite-mail .mail {
  padding: 7px 15px;
}
.settings-invite-mail label {
  margin: 0 0 10px;
}
.settings-invite-mail input {
  width: 100%;
  border: 1px solid #DADADA;
  border-radius: 4px;
}
.settings-invite-role {
  border-top: 1px solid #D2D2D2;
}
.settings-invite-role .text1 {
  margin: 20px 0 0;
}
.settings-invite-role-opts {
  margin: 20px 0 0;
  display: flex;
}
.settings-invite-role-opts img {
  margin: -10px 10px 0 auto;
}
.settings-invite-role-permissions {
  box-sizing: border-box;
  margin: -10px 0;
}
.settings-invite-role-permissions p {
  font-size: 0.85em;
  margin: 0;
}
.settings-invite-role-permissions-views {
  border: 1px solid #A6A6A6;
  border-radius: 10px;
  padding: 10px;
  margin: 0 0 20px;
  min-height: 70px;
}
.settings-invite-role-permissions-views div {
  margin-left: auto;
  display: flex;
  flex-direction: column;
}
.settings-invite-role-contain {
  position: relative;
  margin-top: 20px;
  border-bottom: 1px solid #D2D2D2;
  box-sizing: border-box;
}
.settings-invite-role-contain-views {
  padding: 10px;
  margin: 0 0 20px;
}
.settings-invite-role-contain-views p {
  margin: 0;
}
.settings-invite-action {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}
.settings-invite-action .cancel {
  background: white;
  border: 1px solid #7D7D7D;
  padding: 5px 20px;
  border-radius: 7px;
  color: #7D7D7D;
}
.settings-invite-action .publish {
  background: #0046E6;
  border: 1px solid #0046E6;
  padding: 5px 20px;
  border-radius: 7px;
  color: white;
  margin-left: auto;
}
.settings-invite-action .delete {
  background: #D92D20;
  border: 1px solid #D92D20;
  padding: 5px 20px;
  border-radius: 7px;
  color: white;
  margin-left: auto;
}
.settings-invite-action .disabled {
  background: #D0D0D0;
  border: 1px solid #D0D0D0;
  padding: 5px 20px;
  border-radius: 7px;
  color: white;
  margin-left: auto;
}
.settings_modals-threshold {
  padding: 30px 20px;
  box-sizing: border-box;
}
.settings_modals-threshold h5 {
  margin-top: 15px;
}
.settings_modals-threshold p {
  color: #667085;
}
.settings_modals-threshold input {
  border-radius: 7px;
  width: 100%;
  border: 1px solid #D0D5DD;
  padding: 7px 15px;
}
.settings_modals-threshold .btn1 {
  background: white;
  border: 1px solid #D0D5DD;
  padding: 5px 20px;
  border-radius: 7px;
  color: #344054;
  margin-top: 20px;
}
.settings_modals-threshold .btn2 {
  background: #0046E6;
  border: 1px solid #0046E6;
  padding: 5px 20px;
  border-radius: 7px;
  color: white;
  margin-top: 20px;
  float: right;
}
.settings_modals-threshold .btn3 {
  background: #D0D0D0;
  border: 1px solid #D0D0D0;
  padding: 5px 20px;
  border-radius: 7px;
  color: white;
  margin-top: 20px;
  float: right;
}
@media (max-width: 460px) {
  .settings_hook {
    width: 88%;
    margin: 50px -25px 0 10px !important;
  }
}

.pricing_space {
  margin: 40px 30px;
}
.pricing_box {
  padding: 20px;
  border: 1px solid #EAECF0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 15px;
  margin-bottom: 30px !important;
}
@media (max-width: 769px) {
  .pricing_box {
    margin-right: 20px;
  }
}
@media (max-width: 560px) {
  .pricing_box {
    margin: 0 25px 0 -20px;
  }
}
.pricing_box p {
  color: #606F7B;
}
.pricing_box p span {
  color: black;
  font-size: 1.8em;
  font-weight: 700;
}
.pricing_box-item {
  display: flex;
  align-items: flex-start;
}
.pricing_box-item img {
  width: 20px;
  margin: 2px 5px 0 0;
}

.credit {
  margin: 0 30px 50px;
  box-sizing: border-box;
}
@media (max-width: 575px) {
  .credit {
    margin: 0 30px 50px 5px;
  }
}
.credit-bvn {
  margin: 0 0 50px;
}
.credit-bvn-form {
  display: flex;
  position: relative;
}
@media (max-width: 560px) {
  .credit-bvn-form {
    display: block;
  }
}
.credit-bvn-form .notvisible {
  margin-top: 7px !important;
}
.credit-bvn-input {
  width: 300px;
  height: 40px;
  border: 1px solid #DADADA;
  border-radius: 4px;
  margin-right: 10px;
  padding: 3px 15px;
  color: #8A8A8A;
}
@media (max-width: 560px) {
  .credit-bvn-input {
    width: 100%;
  }
}
.credit-bvn-input-show {
  top: 10px;
  left: 270px;
  position: absolute;
  right: 20px;
  width: 20px;
  cursor: pointer;
}
@media (max-width: 560px) {
  .credit-bvn-input-show {
    right: 10px;
    left: auto;
  }
}
.credit-bvn button {
  background: #0046E6;
  border: 1px solid #0046E6;
  border-radius: 5px;
  height: 40px;
  width: 90px;
  text-align: center;
  color: white;
}
@media (max-width: 560px) {
  .credit-bvn button {
    margin-top: 10px;
  }
}
.credit-bvn-btnInactive {
  background: #8A8A8A !important;
  border: 1px solid #8A8A8A !important;
}
.credit_classic {
  margin: 50px 0 0;
}
.credit_fico {
  background: #FFFFFF;
  border: 0.15px solid #4C8BF5;
  box-sizing: border-box;
  box-shadow: 0px 4px 90px rgba(163, 171, 185, 0.24);
  border-radius: 15px;
  margin: 0 0 30px;
}
.credit_fico hr {
  border: 0.5px solid #0046E6;
}
.credit_fico-single {
  padding: 15px;
}
.credit_fico-single-img {
  background: #0046E6;
  width: 50px;
  height: 50px;
  margin: 0 0 10px;
  border-radius: 50%;
}
.credit_fico-single-img img {
  width: 35px;
  margin: 7px 0 0 8px;
}
.credit_fico-single-top img {
  width: 30px;
  margin: -15px 10px 0 0;
}
.credit_fico-single-top p {
  font-size: 1em;
  font-weight: 700;
  opacity: 0.5;
  margin: 0 !important;
}
.credit_fico-single-text {
  font-size: 1.8em;
  font-weight: 700;
  margin: 0 !important;
}
.credit_fico-single-flex {
  display: flex;
}
.credit_fico-single-flex .average {
  color: #FF8B03;
}
.credit_fico-single-flex .good {
  color: #47D800;
}
.credit_fico-single-flex .bad {
  color: #FF2608;
}
.credit_fico-single-flex .more {
  margin: 7px 0 0 auto;
  font-weight: 700;
  font-size: 1em;
  opacity: 0.5;
  cursor: pointer;
}
.credit_fico-single-flex .more img {
  margin: -15px 0 0 10px;
}
.credit_fico-single-reasons {
  font-size: 1em;
  opacity: 0.5;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-out;
}
.credit_fico-wrapper {
  border-radius: 10px;
  margin: 15px 0;
  padding: 0 !important;
  border: 2px solid #0046E6;
}
.credit_fico-chart-text {
  display: flex;
  align-items: center;
}
.credit_fico-chart-text p {
  margin: 0 0 0 10px;
  font-weight: 700;
  font-size: 1.3em;
  color: #4C8BF5;
}
.credit_fico-chart-main {
  position: relative;
  margin-top: 50px;
  overflow: hidden;
}
.credit_fico-chart-main .chart {
  width: 90%;
  display: block;
  margin: auto;
}
.credit_fico-chart-main .guage {
  border-width: 0px;
  height: 4px;
  width: 60%;
  left: 20%;
  background-color: black;
  position: absolute;
  background-color: #000000;
  background-image: linear-gradient(270deg, #ffffff 50%, #000000 50%);
  bottom: 5px;
  border-radius: 15px;
}
.credit_boxes {
  box-sizing: border-box;
  margin: 30px 20px 30px 0;
}
.credit_boxes-title {
  margin: 0 0 40px;
}
@media (min-width: 1200px) {
  .credit_boxes-title {
    display: flex;
  }
}
.credit_boxes-title-info {
  padding: 15px 0 0;
  font-weight: 600;
}
.credit_boxes-title-info p {
  margin: 0 20px 5px 0;
}
.credit_boxes-title-info-flex {
  display: flex;
}
.credit_boxes-title span {
  font-size: 15px;
  margin-left: 10px;
  font-weight: 400;
}
.credit_boxes-title-buttons {
  margin-left: auto;
}
.credit_boxes-title-buttons button {
  border: 1px solid #0046E6;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 5px 15px;
  font-size: 1em;
  background: #FFFFFF;
}
.credit_boxes-title-buttons button img {
  width: 20px;
  margin: -3px 4px 0 0;
}
.credit_boxes-title-buttons .share {
  margin: 0 10px 0 0;
}
.credit_boxes-single {
  background: #FFFFFF;
  border: 0.15px solid #4C8BF5;
  box-sizing: border-box;
  box-shadow: 0px 4px 90px rgba(163, 171, 185, 0.24);
  border-radius: 15px;
  padding: 15px;
  margin: 0 0 30px;
}
.credit_boxes-single-top {
  display: flex;
}
.credit_boxes-single-top img {
  width: 30px;
  margin: -15px 10px 0 0;
}
.credit_boxes-single-top p {
  font-size: 1em;
  font-weight: 700;
  opacity: 0.5;
}
.credit_boxes-single-top p span {
  font-size: 0.85em;
  font-weight: 500;
}
.credit_boxes-single-text {
  font-size: 1.3em;
  margin: 0 !important;
}
.credit_boxes-single-patternIcon {
  margin: -10px 0 0 auto !important;
  width: 40px !important;
}
.credit_boxes-single-patternText {
  color: #1F4173;
  font-size: 1.3em;
  margin: 0 !important;
}
.credit_boxes-singleNew {
  background: #FFFFFF;
  border: 0.717542px solid #EAECF0;
  box-shadow: 0px 0.717542px 2.15263px rgba(16, 24, 40, 0.1), 0px 0.717542px 1.43508px rgba(16, 24, 40, 0.06);
  box-sizing: border-box;
  border-radius: 15px;
  padding: 15px;
  margin: 0 0 30px;
}
.credit_boxes-singleNew-top {
  display: flex;
  height: 45px;
  align-items: flex-start;
}
.credit_boxes-singleNew-top img {
  width: 35px;
  height: 35px;
  margin-right: 5px;
}
@media (min-width: 1200px) {
  .credit_boxes-singleNew-top img {
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1300px) {
  .credit_boxes-singleNew-top img {
    width: 35px;
    height: 35px;
  }
}
.credit_boxes-singleNew-top p {
  font-size: 1em;
  font-weight: 600;
  color: #101828;
}
@media (min-width: 1200px) {
  .credit_boxes-singleNew-top p {
    font-size: 0.92em;
  }
}
@media (min-width: 1300px) {
  .credit_boxes-singleNew-top p {
    font-size: 1em;
  }
}
.credit_boxes-singleNew-text {
  font-size: 1.5em;
  font-weight: 700;
  margin: 0 !important;
}
.credit_enquiry-topText {
  font-size: 1.2em;
}
.credit_enquiry-data {
  border: 1px solid #EAECF0;
  box-sizing: border-box;
  box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
  border-radius: 0 0 10px 10px !important;
  margin: 0 0 40px;
}
.credit_enquiry-data table {
  width: 100%;
}
.credit_enquiry-data tr {
  color: #0F123F;
  font-size: 1em;
  border-bottom: 1px solid #EAECF0;
}
@media (max-width: 560px) {
  .credit_enquiry-data tr {
    font-size: 0.9em;
  }
}
.credit_enquiry-data th, .credit_enquiry-data td {
  padding: 10px;
}
.credit_enquiry-data th {
  opacity: 0.56;
  font-weight: 400;
  text-transform: capitalize !important;
}
.credit_enquiry-data .view {
  border: 1px solid #47D800;
  background-color: #47D800;
  border-radius: 4px;
  margin-right: 10px;
  color: white;
  width: 100px;
}
.credit_enquiry-data .view img {
  margin-bottom: 3px;
  margin-right: 8px;
}

.select {
  position: relative;
}
.select_box {
  width: 100%;
  height: 40px;
  padding: 7px 15px;
  font-size: 1em;
  border: 2px solid #C4C4C4;
  border-radius: 5px;
  color: #8A8A8A;
  overflow: hidden;
  margin: 10px 0;
  cursor: pointer;
}
.select_box:active {
  border: 2px solid #296AFC;
}
.select_options {
  width: 100%;
  max-height: 500px;
  overflow-y: scroll;
  position: absolute;
  top: 50px;
  border-radius: 5px;
  left: 0;
  right: 0;
  padding: 10px 10px;
  background-color: white;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.09);
  z-index: 2;
}
.select_options p {
  padding: 5px 5px;
  border-radius: 4px;
  color: #8A8A8A;
  cursor: pointer;
  margin: 0 !important;
}
.select_options p:hover {
  background: rgba(43, 78, 158, 0.09);
}
.select_options_selected {
  background: rgba(43, 78, 158, 0.09);
}
.select_arrow {
  position: absolute;
  top: 20px;
  right: 15px;
  pointer-events: none;
}
.select_arrow-type {
  position: absolute;
  top: 28px;
  right: 15px;
  pointer-events: none;
}

.identity_share {
  display: flex;
  align-items: center;
  height: 100% !important;
}
@media (max-width: 560px) {
  .identity_share {
    display: block;
  }
}
.identity_share p {
  margin-right: 1.56rem;
  margin-bottom: 0;
}
.identity_share img {
  margin: -5px 7px 0 0;
  width: 15px;
}
.identity_share div {
  background: #0046e6;
  padding: 7px 15px;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.875rem;
}
@media (max-width: 560px) {
  .identity_share div {
    width: 90px;
    margin-top: 7px;
  }
}
.identity_share .inactiveBtn {
  background: #8A8A8A !important;
}
.identity_empty {
  max-width: 600px;
  margin: 40px auto 30px;
  display: block;
  text-align: center;
}
.identity_empty img {
  width: 250px;
}
.identity_empty h4 {
  margin: 15px 0;
}
.identity_empty p span {
  color: #296AFC;
  cursor: pointer;
}
.identity_empty button {
  background-color: #0046E6;
  font-size: 1em;
  padding: 3px 10px;
  color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: left;
}
.identity_empty button img {
  margin: -3px 5px 0 0;
  width: 15px;
}
.identity_details {
  border: 1px solid #296AFC;
  margin: 30px 0;
  border-radius: 15px 0 15px 0;
}
@media (min-width: 769px) {
  .identity_details {
    display: flex;
    max-width: 800px;
  }
}
.identity_details-section1 {
  font-weight: 600;
}
@media (min-width: 769px) {
  .identity_details-section1 {
    border-right: 1px solid #296AFC;
    width: 50%;
  }
}
.identity_details-section1 .key {
  color: #828282;
}
.identity_details-section1 .numberKey {
  color: #828282;
  margin: 0;
}
.identity_details-section1 .value {
  color: #0F123F;
  margin-left: auto;
}
@media (max-width: 585px) {
  .identity_details-section1 .value {
    flex: 1;
  }
}
.identity_details-section1 .top-Text {
  margin: 0 !important;
}
.identity_details-section1-top {
  padding: 15px;
}
@media (max-width: 585px) {
  .identity_details-section1-top .column {
    flex-direction: column;
  }
}
.identity_details-section1-top .flex {
  display: flex;
}
.identity_details-section1-top .flex p {
  text-align: right;
}
.identity_details-section1-top .flex .phoneNumber {
  margin-bottom: 10px;
}
@media (max-width: 585px) {
  .identity_details-section1-top .flex .phoneNumber {
    flex: 0.5;
  }
}
.identity_details-section1-top .flex .phoneNumber .submit {
  margin: 10px 0;
  background: #0046e6;
  color: #fff;
  padding: 1px;
  border-radius: 3px;
  height: 20px;
}
.identity_details-section1-top .flex .phoneNumber .inputBox p {
  text-align: left;
}
.identity_details-section1-top .flex button {
  background: #fff;
  border: 2px solid #0046e6;
  border-radius: 5px;
  padding: 3px;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 500;
  height: 20px;
}
.identity_bank-top {
  position: relative;
}
.identity_result {
  box-sizing: border-box;
}
.identity_result-top {
  background: #EEF3FF;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 10px 40px;
}
@media (max-width: 769px) {
  .identity_result-top {
    padding: 10px 20px;
    align-items: flex-start;
    margin-top: 60px;
  }
}
@media (max-width: 560px) {
  .identity_result-top {
    padding: 20px 10px;
  }
}
.identity_result-top_image {
  width: 130px;
  background-color: white;
  height: 130px;
  border: 2px solid white;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  flex-shrink: 0;
}
@media (max-width: 769px) {
  .identity_result-top_image {
    width: 60px;
    flex-shrink: 0;
    height: 60px;
  }
}
.identity_result-top_image img {
  width: 100%;
  height: auto;
}
.identity_result-top_image h1 {
  margin-top: 30px;
  font-size: 3.5em;
  text-align: center;
}
@media (max-width: 769px) {
  .identity_result-top_image h1 {
    margin-top: 10px;
    font-size: 2em;
  }
}
.identity_result-top h5 {
  color: #101828;
  font-size: 1.7em;
}
@media (max-width: 560px) {
  .identity_result-top h5 {
    font-size: 1.4em;
  }
}
.identity_result-top p {
  color: #667085;
  font-weight: 700;
}
.identity_result-top_button {
  margin-left: auto;
  width: 35px;
}
@media (max-width: 560px) {
  .identity_result-top_button {
    display: none;
  }
}
.identity_result-box1 {
  padding: 5px 10px;
  margin-top: 10px;
}
.identity_result-box1 .text1 {
  color: #667085;
  margin: 0;
}
.identity_result-box1 .text2 {
  color: #344054;
}
.identity_result-box1 .text3 {
  color: #427CFF;
  text-transform: lowercase;
}
.identity_result-box1-uploadBox {
  display: flex;
  align-items: flex-start;
  border: 1px solid #D0D5DD;
  border-radius: 4px;
  padding: 10px;
  margin-top: 15px;
}
.identity_result-box1-uploadBox h6, .identity_result-box1-uploadBox p {
  margin: 0 0 0 5px;
}
.identity_result-box1-uploadBox-txt {
  overflow: hidden;
  margin-right: 5px;
}
.identity_result-box1-uploadBox-dwnld {
  margin-left: auto;
  border-radius: 4px;
  border: 1px solid #D0D5DD;
  padding: 2px 5px 5px;
  cursor: pointer;
}
.identity_result-box1 .phoneNumber {
  margin-top: 10px;
}
.identity_result-box1 .phoneNumber button {
  background: #fff;
  border: 2px solid #0046e6;
  border-radius: 5px;
  padding: 3px;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 500;
}
.identity_result-box1 .phoneNumber .submit {
  margin: 10px 0;
  background: #0046e6;
  color: #fff;
  padding: 3px 15px;
  border-radius: 3px;
}
.identity_result-box1 .phoneNumber .inputBox {
  border: 1px solid #828282;
  padding: 5px 10px;
  margin: -10px 0 0;
  border-radius: 4px;
}
.identity_result-box1 .phoneNumber .inputBox p {
  text-align: left;
}
.identity_result-tableBox {
  overflow-x: auto;
}
.identity_result-table {
  width: 1800px;
  text-transform: capitalize;
}
.identity_result-table thead {
  background-color: #F0F0F0;
  font-size: 1em;
  border-radius: 5px;
}
.identity_result-table thead th {
  color: #667085;
  padding: 5px 7px;
  font-weight: 400;
}
.identity_result-table tbody {
  font-size: 1em;
}
.identity_result-table tbody td {
  padding: 5px 7px;
}

.credit-card {
  cursor: pointer;
  /* flip speed */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */
}
.credit-card .flip-container.hover .flipper {
  transform: rotateY(180deg);
}
.credit-card .flip-container,
.credit-card .front,
.credit-card .back {
  width: 410px;
  height: 250.5px;
  border-radius: 10px;
}
.credit-card .flipper {
  transition: 0.8s;
  transform-style: preserve-3d;
  position: relative;
}
.credit-card .front,
.credit-card .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.credit-card .front {
  z-index: 2;
  transform: rotateY(0deg);
  color: white;
}
.credit-card .front .credit-card-content {
  display: flex;
  flex-direction: column;
}
.credit-card .front .credit-card-content .top .left .left-content {
  display: flex;
}
.credit-card .front .credit-card-content .top .left .left-content .left-2 {
  margin-left: 7px;
}
.credit-card .front .credit-card-content .top .left .left-content .left-2 h3 {
  font-weight: 200;
}
.credit-card .front .credit-card-content .top .left .left-content .left-2 p {
  font-size: 13px;
}
.credit-card .front .credit-card-content .top .right .right-content {
  text-align: center;
}
.credit-card .front .credit-card-content .top .right .right-content p {
  font-size: 12px;
}
.credit-card .front .credit-card-content .top .right .right-content .linkchain-wrapper {
  border: 1px solid;
  height: 32px;
  width: 32px;
  padding: 6px;
  border-radius: 50%;
  margin-left: 26px;
  margin-bottom: 3px;
}
.credit-card .front .credit-card-content .bottom {
  margin-top: 60px;
}
.credit-card .front .credit-card-content .bottom .left h2 {
  font-weight: 200;
}
.credit-card .front .credit-card-content .bottom .right p {
  margin-top: 32px;
}
.credit-card .back {
  transform: rotateY(180deg);
  color: white;
}
.credit-card .flip-img-wrapper {
  margin-left: 190px;
  margin-top: 0px;
  z-index: 20;
  cursor: pointer;
}
.credit-card .back-money-summary {
  position: absolute;
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  right: 0;
  margin-right: 18px;
  text-align: center;
}
.credit-card .back-money-summary h3 {
  font-weight: 200;
  font-size: 17.5px;
}
.credit-card .back-money-summary p {
  font-size: 13px;
}
.credit-card .back-money-summary .money-received-wrapper {
  margin-bottom: 10px;
}

.billing {
  box-sizing: border-box;
}
.billing_nav {
  font-size: 1.1em;
  font-weight: 600;
  display: flex;
  margin-bottom: 20px;
}
.billing_nav p {
  padding: 5px 10px;
  cursor: pointer;
  color: #000;
}
.billing_nav .active {
  border-bottom: 3px solid #296AFC;
}
.billing_spectrum {
  display: flex;
}
@media (max-width: 1200px) {
  .billing_spectrum {
    display: block;
  }
}
.billing_spectrum-sidebar {
  width: 200px;
}
@media (max-width: 1200px) {
  .billing_spectrum-sidebar {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
}
.billing_spectrum-sidebar p {
  font-weight: 600;
  color: #344054;
  padding: 10px;
  margin: 0;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .billing_spectrum-sidebar p {
    width: 150px;
  }
}
@media (max-width: 769px) {
  .billing_spectrum-sidebar p {
    width: 45%;
  }
}
@media (max-width: 500px) {
  .billing_spectrum-sidebar p {
    width: 100%;
  }
}
.billing_spectrum-sidebar p img {
  width: 20px;
  margin-right: 7px;
}
.billing_spectrum-sidebar .active {
  border-bottom: 1px solid #296AFC;
  border-left: 3px solid #296AFC;
  background: #EAECF0;
}
.billing_spectrum-main {
  width: 100%;
  margin-left: 30px;
}
@media (max-width: 1200px) {
  .billing_spectrum-main {
    margin-left: 0px;
  }
}
.billing_spectrum-main-top {
  border: 1px solid #E2E2E2;
  border-radius: 6px;
  padding: 25px 20px;
  margin-bottom: 30px;
}
.billing_spectrum-main-top-mail {
  display: flex;
  border-bottom: 1px solid #E2E2E2;
}
.billing_spectrum-main-top-mail h5 {
  margin: 0;
}
.billing_spectrum-main-top-mail p {
  color: #475467;
}
.billing_spectrum-main-top-mail-change {
  margin-left: auto;
  cursor: pointer;
}
.billing_spectrum-main-top-mail-change img {
  width: 20px;
  margin: -5px 5px 0 0;
}
.billing_spectrum-main-top label {
  color: #475467;
  margin-top: 20px;
}
.billing_spectrum-main-top label input {
  width: 15px;
}
.billing_spectrum-main-invoice {
  border: 1px solid #E2E2E2;
  border-radius: 6px;
}
.billing_spectrum-main-invoice-usage {
  margin: 20px;
  box-sizing: border-box;
}
.billing_spectrum-main-invoice-table {
  border: 1px solid #E2E2E2;
  margin-top: 7px;
  border-radius: 10px 10px 0 0;
}
.billing_spectrum-main-invoice-table-scroll {
  overflow-x: scroll;
}
.billing_spectrum-main-invoice-table table {
  width: 100%;
  padding-top: 10px;
  color: #667085;
}
@media (max-width: 1200px) {
  .billing_spectrum-main-invoice-table table {
    width: 1000px;
  }
}
.billing_spectrum-main-invoice-table table tr {
  border-bottom: 1px solid #E2E2E2;
}
.billing_spectrum-main-invoice-table table tr th {
  padding: 10px;
  font-size: 0.9em;
  font-weight: 400;
}
.billing_spectrum-main-invoice-table table tr td {
  padding: 10px;
}

.overview {
  box-sizing: border-box;
}
.overview_box1-top-left {
  border: 0.881473px solid #EAECF0;
  box-shadow: 0px 0.881473px 2.64442px rgba(16, 24, 40, 0.1), 0px 0.881473px 1.76295px rgba(16, 24, 40, 0.06);
  border-radius: 7.05178px;
  padding: 10px;
  margin-left: auto;
  position: relative;
}

.float_main {
  width: 600px;
  display: block;
  margin: 70px auto 50px;
}
@media (max-width: 769px) {
  .float_main {
    width: 100%;
  }
}
.float_main-form {
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #EAECF0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 10px 10px 0 0;
  padding: 20px;
  margin-top: 10px;
  box-sizing: border-box;
}
.float_main-form-item label {
  font-size: 0.95em;
  color: #344054;
  font-weight: 500;
}
.float_main-form-item input {
  width: 100%;
  border: 1px solid #DADADA;
  color: #8A8A8A;
  padding: 7px 15px;
  margin: 7px 0 20px;
  border-radius: 7px;
  height: 40px;
}
.float_main-form-item input:focus {
  border: 2px solid #296AFC;
}
.float_main-form-item-withBtn {
  display: flex;
  position: relative;
}
.float_main-form-item-withBtn button {
  background: #0046E6;
  border: 1px solid #0046E6;
  border-radius: 7px;
  color: white;
  margin: 6px 0 25px 10px;
  padding: 7px 10px;
  flex-shrink: 0;
  height: 40px;
  white-space: nowrap;
}
.float_main-form-item-withBtn p {
  position: absolute;
  top: 50px;
  font-size: 0.85em;
}
.float_main-form-item-withBtn img {
  position: absolute;
  right: 80px;
  top: 20px;
  width: 17px;
}
.float_main-form-item-withBtn .process-input {
  border: 1px solid #0046E6;
}
.float_main-form-item-withBtn .process-text {
  color: #7C7C7C;
}
.float_main-form-item-withBtn .success-input {
  border: 1px solid #12B76A;
}
.float_main-form-item-withBtn .success-text {
  color: green;
}
.float_main-form-item-withBtn .failed-input {
  border: 1px solid #FDA29B;
}
.float_main-form-item-withBtn .failed-text {
  color: #F04438;
}
.float_main-actions {
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #EAECF0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 0 0 10px 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.float_main-actions a {
  text-decoration: none;
}
.float_main-actions .next {
  background: #0046E6;
  border: 1px solid #0046E6;
  border-radius: 7px;
  color: white;
  padding: 7px 10px;
  display: block;
  margin-left: 1em;
}
.float_main-actions .edit {
  width: 108px;
  height: 40px;
  background: #E8EFFF;
  border: 1px solid #7BA3FD;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #296AFC;
}
.float_main-actions .back {
  width: 89px;
  background: #A0A0A0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  color: #FFFFFF;
  padding: 10px 10px;
  display: block;
  border: none;
}
.float_main-end {
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #EAECF0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 0 0 10px 10px;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
.float_main-end a {
  text-decoration: none;
}
.float_main-end .next {
  background: #0046E6;
  border: 1px solid #0046E6;
  border-radius: 7px;
  color: white;
  padding: 7px 10px;
  display: block;
  margin-left: 1em;
}
.float_main-end .edit {
  width: 108px;
  height: 40px;
  background: #E8EFFF;
  border: 1px solid #7BA3FD;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #296AFC;
}
.float_main-end .back {
  width: 89px;
  background: #A0A0A0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  color: #FFFFFF;
  padding: 10px 10px;
  display: block;
  border: none;
}
.float_overview {
  margin: -50px 0 0;
  box-sizing: border-box;
}
@media (max-width: 1200px) {
  .float_overview {
    margin: -15px 0px 0;
  }
}
@media (max-width: 992px) {
  .float_overview {
    margin: -15px 40px 0;
  }
}
@media (max-width: 650px) {
  .float_overview {
    margin: -15px 0px 0;
  }
}
.float_overview-topNav {
  display: flex;
  margin-bottom: 30px;
}
.float_overview-topNav p {
  font-weight: 600;
  cursor: pointer;
  color: black;
}
@media (max-width: 500px) {
  .float_overview-topNav p {
    margin: 0 15px 0 0;
  }
}
.float_overview-topNav p img {
  margin: -3px 5px 0 0;
}
@media (max-width: 500px) {
  .float_overview-topNav p img {
    display: none;
  }
}
.float_overview-topNav .active {
  border-bottom: 3px solid #427CFF;
}
.float_overview-box {
  border: 0.760194px solid #EAECF0;
  box-shadow: 0px 0.760194px 2.28058px rgba(16, 24, 40, 0.1), 0px 0.760194px 1.52039px rgba(16, 24, 40, 0.06);
  border-radius: 9.12233px;
  padding: 20px;
  min-height: 150px;
  margin-bottom: 30px;
  position: relative;
}
.float_overview-box .text1 {
  color: #808080;
}
.float_overview-box .text2 {
  color: #0046E6;
  text-decoration: underline;
  cursor: pointer;
  margin: 0;
}
.float_overview-box button {
  border-width: 0px;
  color: white;
  border-radius: 4px;
  padding: 5px 16px;
}
.float_overview-box-dropdown {
  position: absolute;
  bottom: -1.5rem;
}
.float_overview-box-dropdown button {
  background: #82a7ff;
  border: 1px solid #608ffb;
  color: white;
  border-radius: 4px;
  padding: 5px 16px;
}
.float_request {
  box-sizing: border-box;
  margin: 0;
}
@media (max-width: 1200px) {
  .float_request {
    margin: 0;
  }
}
@media (max-width: 992px) {
  .float_request {
    margin: 0 40px;
  }
}
@media (max-width: 650px) {
  .float_request {
    margin: 0;
  }
}
.float_request-box {
  border: 1px solid #E6EDFF;
  box-shadow: 0px 1px 4px rgba(127, 86, 217, 0.2);
  border-radius: 8px;
  padding: 20px;
  margin-top: 30px;
}
@media (max-width: 560px) {
  .float_request-box {
    overflow-x: scroll;
  }
}
.float_request-box table {
  width: 100%;
}
@media (max-width: 560px) {
  .float_request-box table {
    min-width: 600px;
  }
}
.float_request-box table tr {
  border-bottom: 0.75px solid #EAECF0 !important;
  color: #0F123F;
  font-size: 1em;
}
@media (max-width: 560px) {
  .float_request-box table tr {
    font-size: 1em;
  }
}
.float_request-box table th, .float_request-box table td {
  padding: 10px 5px 10px 10px;
}
.float_request-box table th {
  opacity: 0.56;
  font-weight: 400;
  color: #06152B;
  font-size: 0.9em;
  text-transform: capitalize !important;
}
.float_request-box table thead {
  border-bottom: 1px solid #EAECF0 !important;
}
.float_request-item {
  display: flex;
}
.float_request-item .text1 {
  font-weight: 600;
  color: #9E9E9E;
  margin-right: 10px;
}
.float_request-item .text2 {
  font-weight: 600;
  color: #344054;
  margin-left: auto;
  text-transform: capitalize;
}
.float_request-action {
  margin-top: 20px;
}
.float_request-action button {
  padding: 7px;
  text-align: center;
  width: calc(50% - 10px);
  border-radius: 6px;
  color: white;
  font-weight: 700;
  margin: 20px 0;
}
@media (max-width: 1200px) {
  .float_request-action button {
    width: 100%;
    margin: 20px 0 0;
  }
}
@media (max-width: 992px) {
  .float_request-action button {
    width: calc(50% - 10px);
    margin: 20px 0;
  }
}
.float_request-action .decline {
  background: #D92D20;
  border: 1px solid #D92D20;
}
.float_request-action .accept {
  background: #0046E6;
  border: 1px solid #0046E6;
}
.float_request-checks {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}
.float_request-checks input {
  width: 50px;
  margin: 4px 7px 0 -15px;
}
.float_request-checks p {
  margin: 0 0 0 -20px;
  color: #344054;
  font-size: 0.9em;
}
.float_modal {
  padding: 20px;
  box-sizing: border-box;
}
.float_modal-options {
  margin: 15px 0 0;
}
.float_modal-options h5 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  margin: -15px 0 20px;
}
@media (max-width: 560px) {
  .float_modal-options h5 {
    font-size: 20px;
    line-height: 28px;
  }
}
.float_modal-options p {
  font-weight: 500;
  font-size: 16px;
  line-height: 50px;
}
@media (max-width: 560px) {
  .float_modal-options p {
    font-size: 12px;
    line-height: 20px;
  }
}
.float_modal-options .radio [type=radio]:checked,
.float_modal-options .radio [type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.float_modal-options .radio [type=radio]:checked + label,
.float_modal-options .radio [type=radio]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #344054;
  font-weight: 600;
}
.float_modal-options .radio [type=radio]:checked + label:before,
.float_modal-options .radio [type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #0046E6;
  border-radius: 100%;
  background: #fff;
}
.float_modal-options .radio [type=radio]:checked + label:after,
.float_modal-options .radio [type=radio]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #0046E6;
  position: absolute;
  top: 2.7px;
  left: 3px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.float_modal-options .radio [type=radio]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.float_modal-options .radio [type=radio]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.float_modal-action {
  padding: 7px;
  text-align: center;
  color: white;
  background: #0046E6;
  border: 1px solid #0046E6;
  border-radius: 6px;
  width: 100%;
}
.float_modal-terms {
  margin: 40px 20px;
}
.float_modal-terms ::-webkit-scrollbar {
  width: 10px;
}
.float_modal-terms ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}
.float_modal-terms ::-webkit-scrollbar-thumb {
  background: #EEEFF0;
  border-radius: 10px;
}
.float_modal-terms ::-webkit-scrollbar-thumb:hover {
  background: #EEEFF0;
}
.float_modal-terms-title {
  font-size: 1.9em;
  margin: 0;
}
.float_modal-terms-subTitle {
  font-size: 1.1em;
  color: #808080;
  font-weight: 600;
}
.float_modal-terms-scroll {
  max-height: 70vh;
  overflow-y: scroll;
  color: #454545;
}
.float-emailConsent-header {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 1.5em;
}
.float-emailConsent-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}
.float-emailConsent-terms {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #667085;
}

.spectrum {
  margin: 30px 25px 30px 38px;
  box-sizing: border-box;
}
@media (max-width: 560px) {
  .spectrum {
    margin: 30px 0px;
  }
}
.spectrum-topText {
  margin: -50px 12px 40px;
}
@media (max-width: 1500px) {
  .spectrum-topText {
    width: 570px;
  }
}
@media (max-width: 1400px) {
  .spectrum-topText {
    width: 350px;
  }
}
@media (max-width: 1200px) {
  .spectrum-topText {
    width: 100%;
    margin: 40px 0 20px;
  }
}
.spectrum_overview-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
@media (max-width: 560px) {
  .spectrum_overview-top {
    display: block;
  }
}
.spectrum_overview-top button {
  color: #344054;
  border: 1px solid #D0D5DD;
  border-radius: 5px;
  padding: 7px 15px;
  font-weight: 600;
  background: white;
}
.spectrum_overview-shareBox {
  border: 1px solid #D4D8E0;
  border-radius: 7px;
  display: flex;
  min-height: 300px;
}
@media (max-width: 560px) {
  .spectrum_overview-shareBox {
    display: block;
  }
}
.spectrum_overview-shareBox-select {
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 560px) {
  .spectrum_overview-shareBox-select {
    width: 100%;
  }
}
.spectrum_overview-shareBox-select p {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
}
.spectrum_overview-shareBox-select p img {
  width: 20px;
  margin: 6px 10px 0 0;
}
.spectrum_overview-shareBox-select p input[type=checkbox] {
  /* Double-sized Checkboxes */ /* IE */ /* FF */ /* Safari and Chrome */ /* Opera */
  transform: scale(1.3);
  padding: 10px;
  width: 20px;
  margin: 6px 10px 0 -30px;
}
.spectrum_overview-shareBox-select .inactive-text {
  margin-left: 30px;
  color: #98A2B3;
}
.spectrum_overview-shareBox-select .active-text {
  color: #1D2939;
  font-weight: 700;
}
.spectrum_overview-shareBox-content {
  background: #EAF1FD;
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 560px) {
  .spectrum_overview-shareBox-content {
    width: 100%;
  }
}
.spectrum_overview-shareBox-content p {
  color: #667085;
}
.spectrum_overview-shareBox-content button {
  background: #296AFC;
  border: 1px solid #296AFC;
  border-radius: 6px;
  color: white;
  padding: 7px 10px;
  width: 150px;
  display: block;
  margin: auto;
  font-weight: 600;
}
.spectrum_overview-video {
  height: 300px;
  background: url("../images/spectrum-video.png");
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media (max-width: 1200px) {
  .spectrum_overview-video {
    margin-top: 30px;
  }
}
.spectrum_overview-video img {
  width: 50px;
  display: block;
  cursor: pointer;
  margin: auto auto 50px;
}
.spectrum_overview-video h5 {
  margin: 0;
}
.spectrum_overview-tableNav {
  display: flex;
  margin: 40px 0 0;
}
.spectrum_overview-tableNav p {
  padding: 10px 20px;
  color: #667085;
  font-weight: 600;
  cursor: pointer;
}
.spectrum_overview-tableNav .selectedTab {
  color: #003ECC;
  background-color: #E5EEFF;
  border-bottom: 3px solid #003ECC;
}
.spectrum_overview-dataTable {
  border: 1px solid #EAECF0;
  border-radius: 7px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
}
.spectrum_overview-dataTable-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #EAECF0;
}
@media (max-width: 1200px) {
  .spectrum_overview-dataTable-top {
    display: block;
  }
}
.spectrum_overview-dataTable-top h5, .spectrum_overview-dataTable-top p {
  margin: 0;
}
.spectrum_overview-dataTable-top button {
  color: #344054;
  border: 1px solid #D0D5DD;
  border-radius: 5px;
  padding: 7px 15px;
  font-weight: 600;
  width: 150px;
  background: white;
}
@media (max-width: 1200px) {
  .spectrum_overview-dataTable-top button {
    margin-top: 10px;
  }
}
.spectrum_overview-dataTable-top button img {
  width: 17px;
  margin: -2px 5px 0 0;
}
.spectrum_overview-dataTable-top-selection {
  display: flex;
  font-weight: 600;
  cursor: pointer;
}
.spectrum_overview-dataTable-top-selection .search-debt {
  border: 1px solid #D0D5DD;
  padding: 4px 10px;
  border-radius: 5px 0 0 5px;
}
.spectrum_overview-dataTable-top-selection .contribution {
  border: 1px solid #D0D5DD;
  padding: 4px 10px;
  border-radius: 0 5px 5px 0;
}
.spectrum_overview-dataTable-top-selection .active {
  background-color: #D0D5DD;
}
.spectrum_overview-dataTable-top-input {
  width: 400px;
  position: relative;
}
@media (max-width: 1200px) {
  .spectrum_overview-dataTable-top-input {
    margin-top: 10px;
  }
}
@media (max-width: 1200px) {
  .spectrum_overview-dataTable-top-input {
    width: 100%;
  }
}
.spectrum_overview-dataTable-top-input input {
  border: 1px solid #99B8FF;
  border-radius: 7px;
  background: white;
  color: #101828;
  width: 100%;
  padding: 7px 10px 7px 30px;
}
.spectrum_overview-dataTable-top-input img {
  width: 17px;
  position: absolute;
  top: 11px;
  left: 7px;
}
.spectrum_overview-dataTable-empty {
  text-align: center;
  padding: 30px 20px 50px;
  max-width: 400px;
  display: block;
  margin: auto;
}
.spectrum_overview-dataTable-empty-mainIcon {
  width: 50px;
  display: block;
  margin: auto;
}
.spectrum_overview-dataTable-empty h5 {
  font-size: 1.1em;
}
.spectrum_overview-dataTable-empty p {
  color: #475467;
}
.spectrum_overview-dataTable-empty .read {
  color: #344054;
  border: 1px solid #D0D5DD;
  border-radius: 6px;
  padding: 7px 20px;
  font-weight: 600;
  background: white;
  margin: 0 10px 10px 0;
}
.spectrum_overview-dataTable-empty .import {
  background: #296AFC;
  border: 1px solid #296AFC;
  border-radius: 6px;
  color: white;
  padding: 7px 20px;
  font-weight: 600;
}
.spectrum_overview-dataTable-empty .import img {
  margin: -3px 4px 0 0;
}
.spectrum_overview-dataTable-loading {
  margin: 100px 0;
  text-align: center;
}
.spectrum_overview-dataTable-loading img {
  width: 50px;
  display: block;
  margin: auto;
}
.spectrum_overview-dataTable-searchForm {
  margin: 30px 15px;
  display: flex;
  align-items: flex-start;
}
.spectrum_overview-dataTable-searchForm .input-search {
  width: 300px;
}
.spectrum_overview-dataTable-searchForm button {
  padding: 7px 10px;
  margin-left: 10px;
  color: white;
  background: #296AFC;
  border: 1px solid #296AFC;
  border-radius: 6px;
  font-weight: 600;
  width: 100px;
  text-align: center;
}
.spectrum_overview-dataTable-main {
  box-sizing: border-box;
  position: relative;
  padding: 0 15px;
}
@media (max-width: 1200px) {
  .spectrum_overview-dataTable-main-scroll {
    overflow-x: scroll;
  }
}
.spectrum_overview-dataTable-main table {
  width: 100%;
  font-weight: 400;
  font-size: 1em;
}
@media (max-width: 1200px) {
  .spectrum_overview-dataTable-main table {
    width: 1400px;
  }
}
.spectrum_overview-dataTable-main table th {
  color: #475467;
  font-weight: 400;
  font-weight: 1.1em;
  background: #F8F8F8;
  padding: 6px 10px;
  border: 1px solid #EAECF0;
}
.spectrum_overview-dataTable-main table td {
  color: #475467;
  padding: 6px 10px;
  border-bottom: 1px solid #EAECF0;
}
.spectrum_overview-dataTable-main table td .view {
  color: #344054;
  border: 1px solid #D0D5DD;
  border-radius: 6px;
  padding: 4px 10px;
  font-weight: 600;
  background: white;
  margin: 5px 0;
}
.spectrum_overview-dataTable-main table td .view img {
  margin: -3px 4px 0 0;
}
.spectrum_overview-dataTable-main table td .view .load {
  width: 70px;
  height: 20px;
}
.spectrum_pricing-text {
  display: block;
  margin: auto;
  padding: 20px;
  max-width: 500px;
}
.spectrum_pricing-text-1 {
  font-weight: 500;
  color: #296AFC;
}
.spectrum_pricing-text-2 {
  color: #475467;
  font-size: 1.2em;
}
.spectrum_pricing-tablets {
  background: #F9FAFB;
  border: 1px solid #F2F4F7;
  border-radius: 8px;
  display: flex;
  width: 470px;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5px;
  margin: auto auto 30px;
  cursor: pointer;
}
@media (max-width: 560px) {
  .spectrum_pricing-tablets {
    width: 330px;
  }
}
.spectrum_pricing-tablets p {
  margin: 0;
  color: #667085;
  font-weight: 600;
  text-align: center;
  padding: 7px;
}
.spectrum_pricing-tablets .active {
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 6px;
  background: white;
}
.spectrum_pricing-tablets .active p {
  color: #344054;
}
.spectrum_pricing-tablets div {
  width: 150px;
}
.spectrum_pricing-card {
  border: 1px solid #EAECF0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 10px;
  margin-bottom: 30px;
}
.spectrum_pricing-card-top {
  padding: 30px 15px;
  border-bottom: 1px solid #EAECF0;
}
.spectrum_pricing-card .text11 {
  font-size: 1.1em;
  font-weight: 600;
  color: #475467;
  margin: 0;
}
.spectrum_pricing-card .text12 {
  color: #296AFC;
  background: #E5EEFF;
  margin: 0;
  padding: 2px 10px;
  border-radius: 15px;
  margin-left: auto;
  font-size: 0.9em;
  font-weight: 600;
}
.spectrum_pricing-card-price {
  display: flex;
}
.spectrum_pricing-card-price h2 {
  font-weight: 700;
  font-size: 2.6em;
  margin-right: 5px;
}
.spectrum_pricing-card-price img {
  width: 17px;
  margin: 0;
  cursor: pointer;
}
.spectrum_pricing-card-price p {
  margin: 0;
}
.spectrum_pricing-card-start {
  width: 100%;
  padding: 7px 10px;
  margin: 20px 0 10px;
  color: white;
  background: #296AFC;
  border: 1px solid #296AFC;
  border-radius: 6px;
  font-weight: 600;
}
.spectrum_pricing-card-sales {
  width: 100%;
  padding: 7px 10px;
  border-radius: 6px;
  color: #344054;
  border: 1px solid #D0D5DD;
  font-weight: 600;
  background: white;
}
.spectrum_pricing-card-features {
  color: #475467;
  padding: 30px 15px;
}
.spectrum_pricing-card-features h5 {
  font-size: 1.3em;
  color: #101828;
  margin: 0;
}
.spectrum_pricing-card-features-item {
  display: flex;
  align-items: flex-start;
}
.spectrum_pricing-card-features-item img {
  width: 20px;
  margin: 2px 7px 0 0;
}
.spectrum_detail-top {
  width: 100%;
}
.spectrum_detail-top p {
  margin-bottom: 5px;
}
.spectrum_detail-top-title {
  display: flex;
  position: relative;
}
.spectrum_detail-top-title-action {
  margin-left: auto;
  display: flex;
}
.spectrum_detail-top-title-action button {
  padding: 0px 10px 2px;
  border-radius: 6px;
  color: #344054;
  border: 2px solid #D0D5DD;
  font-weight: 600;
  height: 35px;
  background: white;
}
.spectrum_detail-top-title-action button img {
  width: 18px;
  margin: -2px 5px 0 0;
}
.spectrum_detail-top-title-action img {
  margin-top: -3px;
  height: 40px;
  width: 37px;
  margin-left: 10px;
}
.spectrum_detail-top-title-option {
  position: absolute;
  background: white;
  top: 40px;
  right: 0px;
  border-radius: 10px;
  z-index: 2;
  width: 220px;
  overflow: hidden;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
}
.spectrum_detail-top-title-option p {
  margin: 0;
  padding: 7px 15px;
  cursor: pointer;
}
.spectrum_detail-top-title-option p img {
  margin: -2px 7px 0 0;
}
.spectrum_detail-data {
  border: 1px solid #ECECEC;
  border-radius: 15px;
  padding: 15px 15px 5px;
  margin: 30px auto 10px;
}
.spectrum_detail-line {
  margin: 30px 0;
}
.spectrum_detail-mix-title {
  display: flex;
  font-size: 0.9em;
  color: #667085;
}
.spectrum_detail-mix-title img {
  margin: -20px 5px 0 0;
}
.spectrum_detail-mix-items {
  display: flex;
}
.spectrum_detail-mix .norm {
  font-size: 0.9em;
  border: 1px solid #EFF8FF;
  color: #175CD3;
  font-weight: 600;
  border-radius: 25px;
  background: #EFF8FF;
  text-align: center;
  cursor: default;
  padding: 2px 10px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.spectrum_detail-mix .norm div {
  background: #175CD3;
  width: 5px;
  margin-right: 4px;
  height: 5px;
  font-size: 2em;
  border-radius: 50%;
}
.spectrum_detail-mix .err {
  font-size: 0.9em;
  border: 1px solid #FFF1F3;
  color: #C01048;
  font-weight: 600;
  cursor: default;
  border-radius: 25px;
  background: #FFF1F3;
  text-align: center;
  padding: 2px 10px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.spectrum_detail-mix .err div {
  background: #C01048;
  width: 5px;
  margin-right: 4px;
  height: 5px;
  font-size: 2em;
  border-radius: 50%;
}
.spectrum_detail-mix .good {
  font-size: 0.9em;
  border: 1px solid #ECFDF3;
  color: #027A48;
  font-weight: 600;
  cursor: default;
  border-radius: 25px;
  background: #ECFDF3;
  text-align: center;
  padding: 2px 10px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.spectrum_detail-mix .good div {
  background: #027A48;
  width: 5px;
  margin-right: 4px;
  height: 5px;
  font-size: 2em;
  border-radius: 50%;
}
.spectrum_detail-versionInfo {
  position: relative;
}
.spectrum_detail-versionInfo-option {
  position: absolute;
  background: white;
  top: 45px;
  right: 0;
  border-radius: 10px;
  z-index: 2;
  width: 170px;
  overflow: hidden;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
}
.spectrum_detail-versionInfo-option p {
  margin: 0;
  padding: 7px 10px;
  cursor: pointer;
}
.spectrum_detail-versionInfo-option .text1 {
  color: #344054;
}
.spectrum_detail-versionInfo-option .text1 span {
  color: #8C8C8C;
}
.spectrum_detail-versionInfo-option .text1 img {
  margin-top: -2px;
  float: right;
}
.spectrum_detail-versionInfo-option .text2 {
  color: #667085;
  background-color: #E7EAF1;
}
.spectrum_detail-versionInfo-option .text2 span {
  color: #8C8C8C;
}
.spectrum_detail-info {
  display: flex;
}
.spectrum_detail-info button {
  z-index: 2;
  color: white;
  border: 1px solid #0046E6;
  border-radius: 12px 0 12px 0;
  padding: 4px 10px;
  height: 40px;
  font-weight: 600;
  background: #0046E6;
  margin: 0 0 0 auto;
}
.spectrum_detail-info button img {
  margin: -3px 4px 0 0;
}
.spectrum_detail-history {
  display: flex;
}
.spectrum_detail-history .fullTable {
  width: 100%;
}
.spectrum_detail-history-table {
  border: 1px solid #D6D6D6;
  margin-bottom: 30px;
}
.spectrum_detail-history-table-top {
  display: flex;
  color: white;
  font-weight: 600;
  font-size: 1em;
  background: #98A2B3;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
  position: relative;
}
.spectrum_detail-history-table-top p {
  margin: 10px 0;
}
.spectrum_detail-history-table-top .last {
  font-size: 0.9em;
  cursor: pointer;
  text-decoration: underline;
}
.spectrum_detail-history-table-top-option {
  position: absolute;
  background: white;
  top: 35px;
  right: 15px;
  border-radius: 10px;
  z-index: 2;
  overflow: hidden;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
}
.spectrum_detail-history-table-top-option p {
  margin: 0;
  padding: 7px 10px;
  cursor: pointer;
}
.spectrum_detail-history-table-top-option .text1 {
  color: #344054;
}
.spectrum_detail-history-table-top-option .text1 span {
  color: #8C8C8C;
}
.spectrum_detail-history-table-top-option .text1 img {
  margin-top: -2px;
  float: right;
}
.spectrum_detail-history-table-top-option .text2 {
  color: #667085;
  background-color: #E7EAF1;
}
.spectrum_detail-history-table-top-option .text2 span {
  color: #8C8C8C;
}
.spectrum_detail-history-table-bottom {
  font-weight: 500;
}
.spectrum_detail-modal h4 {
  margin-top: 15px;
}
.spectrum_detail-modal p {
  margin: 0;
  color: #667085;
}
.spectrum_detail-modal .name {
  color: black;
}
.spectrum_detail-modal-item {
  margin: 0 15px;
}
.spectrum_import-box {
  max-width: 500px;
  border-radius: 10px;
  background: #F2F4F7;
  padding: 30px;
  margin: 30px 0 50px;
}
@media (max-width: 400px) {
  .spectrum_import-box {
    padding: 15px;
  }
}
.spectrum_import-box-csv {
  background: white;
  border-radius: 15px;
}
.spectrum_import-box-csv h5 {
  border-bottom: 1px solid #D0D5DD;
  padding: 20px;
}
@media (max-width: 400px) {
  .spectrum_import-box-csv h5 {
    padding: 15px;
  }
}
.spectrum_import-box-csv .main {
  padding: 20px;
}
@media (max-width: 400px) {
  .spectrum_import-box-csv .main {
    padding: 15px;
  }
}
.spectrum_import-box-csv-form {
  position: relative;
}
.spectrum_import-box-csv-form input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #D0D5DD;
  padding: 7px 15px;
  margin-bottom: 10px;
  cursor: default;
}
.spectrum_import-box-csv-form .notSelected {
  color: #98A2B3;
}
.spectrum_import-box-csv-form .selected {
  text-transform: capitalize;
}
.spectrum_import-box-csv-form .down {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}
.spectrum_import-box-csv-form button {
  border-radius: 6px;
  color: white;
  padding: 7px 10px;
  width: 100%;
  font-weight: 600;
}
.spectrum_import-box-csv-form .aaa {
  background: #EAECF0;
  border: 1px solid #EAECF0;
}
.spectrum_import-box-csv-form .bbb {
  background: #3372FF;
  border: 1px solid #3372FF;
}
.spectrum_import-box-csv-form-options {
  position: absolute;
  top: 50px;
  width: 100%;
  background: white;
  border: 1px solid #F2F4F7;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}
.spectrum_import-box-csv-form-options div {
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.spectrum_import-box-csv-form-options div .img1 {
  margin-right: 10px;
}
.spectrum_import-box-csv-form-options div .img2 {
  margin-left: auto;
}
.spectrum_import-box-csv-form-options div p {
  margin: 0;
}
.spectrum_import-box-csv-upload {
  border: 1px dashed #6695FF;
  padding: 30px 20px;
  text-align: center;
  margin: 30px 0;
}
.spectrum_import-box-csv-upload p {
  font-weight: 600;
  color: #296AFC;
  cursor: pointer;
}
.spectrum_import-box-csv-upload p span {
  color: #475467;
  cursor: default;
  font-weight: 400;
}
.spectrum_import-box-csv-progress {
  display: flex;
  align-items: flex-start;
  border-radius: 12px;
  padding: 15px;
  border: 1px solid #296AFC;
}
.spectrum_import-box-csv-progress .file {
  width: 30px;
  margin-right: 10px;
}
.spectrum_import-box-csv-progress p {
  color: #344054;
  margin: 0;
  font-size: 0.95em;
}
.spectrum_import-box-csv-progress div {
  width: 100%;
}
.spectrum_import-box-csv-progress .mark {
  float: right;
}
.spectrum_import-box-csv-progress progress {
  border-radius: 7px;
  width: 100%;
  height: 8px;
  margin: 8px 5px 0 0;
  box-sizing: border-box;
}
.spectrum_import-box-csv-progress progress::-webkit-progress-bar {
  background-color: white;
  border: 0.5px solid #296AFC;
  border-radius: 7px;
}
.spectrum_import-box-csv-progress progress::-webkit-progress-value {
  background-color: #296AFC;
  border-radius: 7px;
}
.spectrum_import-box-csv-progress progress::-moz-progress-bar {
  /* style rules */
}
.spectrum_import-tableBox {
  position: relative;
}
.spectrum_import-tableBox-topLayer {
  height: 38px;
  background: #F2F2F2;
  position: absolute;
  width: 100%;
}
.spectrum_pagination {
  display: flex;
  justify-content: space-between;
  margin: 20px 15px;
  align-items: center;
}
.spectrum_pagination p {
  margin: 0px;
  padding: 5px 10px;
}
.spectrum_pagination .active {
  background-color: #D0D5DD;
  border-radius: 5px;
}
.spectrum_pagination button {
  color: #344054;
  border: 2px solid #D0D5DD;
  border-radius: 5px;
  padding: 7px 15px;
  font-weight: 600;
  background: white;
}
.spectrum_pagination button img {
  width: 20px;
  margin-top: -2px;
}
.spectrum_pagination-next img {
  transform: rotate(180deg);
}

.auth_signup {
  margin-top: 30px;
  box-sizing: border-box;
}
.auth_signup-form label {
  margin: 5px 0 0px !important;
  font-weight: 500;
  color: #344054;
}
.auth_signup input {
  width: 100%;
  margin: 10px 0;
  padding: 5px 18px;
  height: 40px;
  border: 2px solid #C4C4C4;
  border-radius: 5px;
  color: #8A8A8A;
}
.auth_signup input:focus {
  border: 2px solid #296AFC !important;
  outline: none !important;
}
.auth_signup input:-webkit-autofill {
  border: 2px solid #C4C4C4;
}
.auth_signup option {
  padding: 10px 15px;
  font-size: 2em;
}
.auth_signup-password {
  position: relative;
}
.auth_signup-password img {
  position: absolute;
  right: 20px;
  width: 20px;
  cursor: pointer;
}
.auth_signup-password .notvisible {
  top: 30px;
}
.auth_signup-password .visible {
  top: 23px;
}
.auth_signup-passwordCheck {
  padding: 0;
}
.auth_signup-passwordCheck li {
  list-style: none;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.0125em;
  color: #8A8A8A;
  margin-top: 5px;
}
.auth_signup-passwordCheck li img {
  margin-right: 10px;
}
.auth_signup-submit {
  width: 100%;
  padding: 10px 0;
  text-align: center;
  margin: 30px 0;
  border-radius: 7px;
  border: 1px solid #D0D0D0;
  background: #D0D0D0;
  font-size: 1.1em;
  font-weight: 600;
  color: white;
}
.auth_signup-active {
  width: 100%;
  padding: 10px 0;
  text-align: center;
  margin: 30px 0;
  border-radius: 7px;
  border: 1px solid #296AFC;
  background: #296AFC;
  font-size: 1.1em;
  font-weight: 600;
  color: white;
}
.auth_otp {
  position: relative;
  margin-top: 40px;
}
.auth_otp input {
  margin: 10px 0px 10px 0;
  padding: 10px 15px;
  border: 2px solid #C4C4C4;
  border-radius: 5px;
  width: 50px !important;
  height: 45px !important;
  color: #8A8A8A;
}
@media (max-width: 450px) {
  .auth_otp input {
    width: 12% !important;
    height: 40px !important;
    margin: 10px 15px 10px 0 !important;
    padding: 5px !important;
  }
}
@media (max-width: 400px) {
  .auth_otp input {
    margin: 10px 10px 10px 0 !important;
  }
}
.auth_otp input:focus {
  border: 2px solid #296AFC;
  outline: none !important;
}
.auth_otp-show {
  width: 20px;
  position: absolute;
  top: 25px;
  right: 17px;
  cursor: pointer;
}
@media (max-width: 450px) {
  .auth_otp-show {
    top: 20px;
    right: 0;
  }
}
.auth_otp-text1 span {
  color: #296AFC;
  cursor: pointer;
}

@media (max-width: 992px) {
  .dashboard_sidebar {
    transition: 2s;
  }
}
.dashboard_sidebar-main {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  /* overflow-y: scroll; */
}
.dashboard_main-alert {
  display: flex;
  justify-content: space-between;
  width: 65%;
  border-radius: 8px;
  padding: 12px 15px;
  margin: 20px 0 0 40px;
  text-decoration: none;
}
.dashboard_main-alert a {
  text-decoration: none;
}
.dashboard_main-alert span {
  display: flex;
}
.dashboard_main-alert span p {
  margin: 0;
  padding: 0 10px;
}
@media (max-width: 992px) {
  .dashboard_back {
    display: none;
  }
}/*# sourceMappingURL=main.css.map */