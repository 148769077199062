// @import "../../styles/scss/variables.scss";
.credit-card {
  cursor: pointer;
  .flip-container.hover .flipper {
    transform: rotateY(180deg);
  }

  .flip-container,
  .front,
  .back {
    width: 410px;
    height: 250.5px;
    border-radius: 10px;
  }

  /* flip speed */
  .flipper {
    transition: 0.8s;
    transform-style: preserve-3d;
    position: relative;
  }

  /* hide back of pane during swap */

  .front,
  .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }

  /* front pane, placed above back */

  .front {
    z-index: 2;
    transform: rotateY(0deg);
    color: white;
    .credit-card-content {
      display: flex;
      flex-direction: column;
      .top {
        .left {
          .left-content {
            display: flex;
            .left-2 {
              margin-left: 7px;
              h3 {
                font-weight: 200;
              }
              p {
                font-size: 13px;
              }
            }
          }
        }
        .right {
          .right-content {
            p {
              font-size: 12px;
            }
            text-align: center;
            .linkchain-wrapper {
              border: 1px solid;
              height: 32px;
              width: 32px;
              padding: 6px;
              border-radius: 50%;
              margin-left: 26px;
              margin-bottom: 3px;
            }
          }
        }
      }
      .bottom {
        margin-top: 60px;
        .left {
          h2 {
            font-weight: 200;
          }
        }
        .right {
          p {
            margin-top: 32px;
          }
        }
      }
    }
  }

  /* back, initially hidden pane */

  .back {
    transform: rotateY(180deg);
    // background: purple;
    color: white;
  }

  .flip-img-wrapper {
    margin-left: 190px;
    margin-top: 0px;
    z-index: 20;
    cursor: pointer;
  }

  .back-money-summary {
    position: absolute;
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    right: 0;
    margin-right: 18px;
    text-align: center;
    h3 {
      font-weight: 200;
      font-size: 17.5px;
    }
    p {
      font-size: 13px;
    }
    .money-received-wrapper {
      margin-bottom: 10px;
    }
  }
}
