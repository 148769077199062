.apps{

    &_create{
        border: 1px solid #0046E6;
        box-sizing: border-box;
        border-radius: 15px;
        box-sizing: border-box;
        width: 400px;
        padding: 20px;
        margin: 30px 40px;

        @media(max-width: $mobile){
            width: 90%;
            margin: 30px 60px 30px 0px;
        }

        &-input{
            width: 100%;
            border: 1px solid #DADADA;
            color: $color-formInput;
            padding: 5px 15px;
            margin: 10px 0;
            border-radius: 7px;
        }

        &-button{
            padding: 5px 20px;
            border-radius: 7px;
            width: 100%;
            margin: 10px 0 20px;
            color: white;
            background: $color-sidebar;
            border: 1px solid $color-sidebar;
        }

        &-btnInactive{
            padding: 5px 20px;
            border-radius: 7px;
            width: 100%;
            margin: 10px 0 20px;
            color: white;
            background: $color-formInput;
            border: 1px solid $color-formInput;
        }
    }
}