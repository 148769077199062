.myModal{
    &_blurBg{
        // position: relative;
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        right: -50px;
        bottom: -50px;
        z-index: 3;
        background: rgba(183, 233, 246, 0.25);
        backdrop-filter: blur(16px);
    }

    &_main{
        max-width: 400px;
        border: 1px solid #0046E6;
        background: white;
        border-radius: 10px;
        z-index: 3;
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: 560px) {
            width: 300px;
            
        }

        &_privacy {
            max-width: 400px;
             max-height: 600px;
            border: 1px solid #0046E6;
            background: white;
            border-radius: 10px;
            z-index: 3;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            // top: 10vh;
            // left: calc(50% - 173px);
            padding: 10px;
            margin: 10px;
            overflow-y: scroll;

            .top {
                display: flex;
                justify-content: space-between;
                h3{
                    padding: 20px;
                }

                h1 {
                    font-weight: 600;
                    font-size: 17.9141px;
                    line-height: 31px;
                    align-items: center;
                    color: #00072B;
                }
            }

            table {
                width: 350px !important;
                text-align: center;
            }
        }

        &-widget{

            &-header {
                display: flex;
                align-items: center;
                justify-content: center;
                // padding: 20px 0;

                p {
                    font-family: 'DM Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 35px;
                    margin: 0 20px 0 0;
                    /* flex: 1.5; */
                    text-align: start;
                    width: 60%
                }
            }

            &-upload{
                display: flex;
                justify-content: space-between;
                box-sizing: border-box;
                /* background: #E3E4E7; */
                padding: 4px 20px 4px 10px;
                width: 100%;
                margin: 30px 0;
                border-radius: 8px;
                border: 1px solid #E3E4E7;

                img{
                    width: 30px;
                }
                p{
                    margin: 3px 0 0 10px;
                }
            }

            &-share {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin: 0;
                img {
                    width: 75px;
                    height: 75px;
                    margin-bottom: 20px;
                    @media (max-width: 560px) {
                        width: 75px;
                        height: 75px;
                        margin-bottom: 10px;
                    }
                }
                h4 {
                    font-family: 'DM Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 27px;
                    line-height: 35px;
                    @media (max-width: 560px) {
                        font-size: 20px;
                        line-height: 28px;
                    }
                }
                p {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 26px;
                    @media (max-width: 560px) {
                        font-size: 12px;
                        line-height: 20px;
                    }
                }

                &-action {
                    display: flex;
                    justify-content: space-around;
                    .cancel {
                        background: #D0D0D0;
                          border: 1px solid #D0D0D0;
                          padding: 5px 20px;
                          border-radius: 7px;
                          color: white;
                          margin-left: auto;
                    }

                    .share {
                        background: #0046E6;
                          border: 1px solid #0046E6;
                          padding: 5px 20px;
                          border-radius: 7px;
                          color: white;
                    }

                    .decline {
                        background: #D0D0D0;
                          border: 1px solid #D0D0D0;
                          padding: 5px 20px;
                          border-radius: 7px;
                          color: white;
                    }

                    .goBack {
                        background: white;
                          border: 1px solid #D0D5DD;
                          padding: 5px 20px;
                          border-radius: 7px;
                          color: #344054;
                    }
                }

                &-kyc {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    button{
                    background: #0046E6;
                    border: 1px solid #0046E6;
                    margin-left: auto;
                    width: 100%;
                    color: #fff;
                    padding: 5px;
                    border-radius: 7px;
                     }
                    p{
                        font-size: 16px;;
                        margin: 16px 0;
                        color: #0046E6;
                        font-style: normal;
                        font-weight: 700;
                        letter-spacing: 0.5px;
                        cursor: pointer;
                        @media (max-width: 560px) {
                            font-size: 12px;
                            margin: 12px 0;
                        }

                        a {
                            text-decoration: none;
                        }

                    }

                    .link {
                        background: #0046E6;
                        border: 1px solid #0046E6;
                        padding: 8px 24px;
                        border-radius: 7px;
                        color: white;
                        width: 200px !important;
                        font-size: 12px;
                    }
                }

                &-icons{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin: 15px 0;
                }

                &-tick{
                    display: flex;
                    align-items: center;
                    justify-content:center;

                    .tick{
                        width: 20px ;
                        height: 20px;
                        margin: 0;
                    }
                    h6{
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 26px;
                        margin: 0 10px;
                    }
                }
            }

            &-shareLeft {
                margin: 0;
                img {
                    width: 60px;
                    height: 60px;
                    margin-bottom: 20px;
                    @media (max-width: 560px) {
                        width: 75px;
                        height: 75px;
                        margin-bottom: 10px;
                    }
                }
                h4 {
                    font-family: 'DM Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 27px;
                    line-height: 35px;
                    @media (max-width: 560px) {
                        font-size: 20px;
                        line-height: 28px;
                    }
                }
                p {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 26px;
                    @media (max-width: 560px) {
                        font-size: 12px;
                        line-height: 20px;
                    }
                }

                .radio{
                    [type="radio"]:checked,
                    [type="radio"]:not(:checked) {
                        position: absolute;
                        left: -9999px;
                    }
                    [type="radio"]:checked + label,
                    [type="radio"]:not(:checked) + label
                    {
                        position: relative;
                        padding-left: 28px;
                        cursor: pointer;
                        line-height: 20px;
                        display: inline-block;
                        color: #344054;;
                        font-weight: 600;
                    }
                    [type="radio"]:checked + label:before,
                    [type="radio"]:not(:checked) + label:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 18px;
                        height: 18px;
                        border: 1px solid #47D800;
                        border-radius: 100%;
                        background: #fff;
                    }
                    [type="radio"]:checked + label:after,
                    [type="radio"]:not(:checked) + label:after {
                        content: '';
                        width: 12px;
                        height: 12px;
                        background: #47D800;
                        position: absolute;
                        top: 4px;
                        left: 4px;
                        border-radius: 100%;
                        -webkit-transition: all 0.2s ease;
                        transition: all 0.2s ease;
                    }
                    [type="radio"]:not(:checked) + label:after {
                        opacity: 0;
                        -webkit-transform: scale(0);
                        transform: scale(0);
                    }
                    [type="radio"]:checked + label:after {
                        opacity: 1;
                        -webkit-transform: scale(1);
                        transform: scale(1);
                    }
                }
            }

            &-form{
                width: 100%;
                box-sizing: border-box;
                margin: 0 0 30px;
                position: relative;
                
                input{
                    width: 100%;
                    margin: 10px 0;
                    padding: 5px 15px;
                    height: 40px;
                    border: 2px solid #C4C4C4;
                    border-radius: 5px;
                    color: $color-formInput;

                    &:focus{
                        border-color: $color-primary !important;
                    }
                }

                .type{
                    display: flex;
                    justify-content: space-evenly;

                    p{
                        font-weight: 600;
                        padding: 10px 15px 5px ;
                        margin: 0 0 5px;
                        cursor: pointer;
                    }
                    &_active{
                        border-bottom: 3px solid #0046E6;
                    }
                }

                .bank{
                    width: 20px;
                    position: absolute;
                    bottom: 20px;
                    left: 9px;
                }
                
                .eye{
                    width: 20px;
                    cursor: pointer;
                    position: absolute;
                    bottom: 19px;
                    right: 10px;
                }

                &-text1{
                    margin:30px 0 0;
                    font-weight: 600;
                }

                &-dragUpload{
                    border: 1px solid #EAECF0;
                    border-radius: 8px;
                    margin: -15px 0 10px;
                    padding: 20px;
                    cursor: pointer;

                    img{
                        margin: auto;
                        display: block;
                    }
                    p{
                        text-align: center;
                        margin: 0;
                    }
                    .text1{
                        font-weight: 700;
                        color: #0046E6;
                    }
                    .text2{
                        font-size: 0.85em;
                    }
                }
            }

            &-action{
                display: flex;

                .form-submit {
                    background: #0046E6;
                    border: 1px solid #0046E6;
                    margin-left: auto;
                    width: 100%;
                }
                .form-disable {
                    background: #D0D0D0;
                    border: 1px solid #D0D0D0;
                    margin-left: auto;
                    width: 100%;
                }
                button{
                    padding: 5px 20px;
                    border-radius: 7px;
                    color: white;
                }
                .cancel{
                    background: $color-btnInactive;
                    border: 1px solid $color-btnInactive;
                }

                .publish{
                    background: $color-sidebar;
                    border: 1px solid $color-sidebar;
                    margin-left: auto;
                }

                .back{
                    background: white;
                    border: 1px solid #D0D5DD;
                    color: #344054;
                }

                .inactiveBtn{
                    border: 1px solid $color-formInput !important;
                    background: $color-formInput !important;
                }

                &-close{
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;

                    button{
                        padding: 5px 20px;
                        border-radius: 7px;
                        color: white;
                    }

                    .close{
                        background: #D0D0D0;
                        border: 1px solid #D0D0D0;
                        margin: 0 0 20px 0;
                    }
                }
            }
        }

        &-linkBank{
            input{
                border-top: 1px solid transparent;
                border-left: 1px solid transparent;
                border-right: 1px solid transparent;
                border-bottom: 1px solid #757575;
                color: #757575;
                font-size: 1em;
                width: 100%;
                padding: 5px;
                margin: 30px 0;

                &:focus{
                    outline: none;
                }
            }

            &-toggle{
                display: flex;
                margin: 20px 0 -30px;
                &-btn{
                    margin-left: auto;
                }
            }

            &-radio{
                [type="radio"]:checked,
                [type="radio"]:not(:checked) {
                    position: absolute;
                    left: -9999px;
                }
                [type="radio"]:checked + label,
                [type="radio"]:not(:checked) + label
                {
                    position: relative;
                    padding-left: 28px;
                    cursor: pointer;
                    line-height: 20px;
                    display: inline-block;
                    color: #666;
                }
                [type="radio"]:checked + label:before,
                [type="radio"]:not(:checked) + label:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 18px;
                    height: 18px;
                    border: 2px solid #0046E6;
                    border-radius: 100%;
                    background: #fff;
                }
                [type="radio"]:checked + label:after,
                [type="radio"]:not(:checked) + label:after {
                    content: '';
                    width: 13px;
                    height: 13px;
                    background: #0046E6;
                    position: absolute;
                    top: 4.5px;
                    left: 4.5px;
                    border-radius: 100%;
                    -webkit-transition: all 0.2s ease;
                    transition: all 0.2s ease;
                }
                [type="radio"]:not(:checked) + label:after {
                    opacity: 0;
                    -webkit-transform: scale(0);
                    transform: scale(0);
                }
                [type="radio"]:checked + label:after {
                    opacity: 1;
                    -webkit-transform: scale(1);
                    transform: scale(1);
                }
            }
        }

        &-shareReport{
            input{
                border-top: 1px solid transparent;
                border-left: 1px solid transparent;
                border-right: 1px solid transparent;
                border-bottom: 1px solid #757575;
                color: #757575;
                font-size: 1em;
                width: 100%;
                padding: 5px;
                margin: 0 0 15px;

                &:focus{
                    outline: none;
                }
            }
        }

        &_password{
            input{
                width: 100%;
                border: 1px solid #DADADA;
                border-radius: 4px;
                color: #757575;
                font-size: 1em;
                width: 100%;
                padding: 5px;
                margin: 5px 0 15px;
            }
        }
    }

    &_newshare{
        width: 400px;
        border-radius: 10px;
        z-index: 3;
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: 560px) {
            width: 300px;
        }

        &-content{
            &-wa{
                padding: 30px 30px 40px;
                box-sizing: border-box;
                background-color: white;
                border-radius: 0 0 10px 10px;

                .mail{
                    padding: 7px 15px;
                }
                label{
                    margin: 0 0 10px;
                }
                input{
                    width: 100%;
                    border: 1px solid #DADADA;
                    border-radius: 4px;
                }
            }
        }
    }

    &_newerShare{

        &-content{

            &-wa{
                padding: 30px 15px 40px;
                box-sizing: border-box;
                background-color: white;
                border-radius: 0 0 10px 10px;

                .mail{
                    padding: 7px 15px;
                }
                label{
                    margin: 0 0 10px;
                }
                input{
                    width: 100%;
                    border: 1px solid #DADADA;
                    border-radius: 4px;
                }
            }

            &-mail{

                .div1{
                    display: flex;
                }
                .btn2{
                    background-color: transparent;
                    border: 1px solid transparent;
                    border-radius: 6px;
                    color: #0046E6;
                    padding: 10px 0;
                    width: 70px;
                    margin-left: 10px;
                    text-decoration: underline;
                    font-weight: 500;
                }

                .div2{
                    display: flex;
                    box-sizing: border-box;
                }

                .div3{
                    background-color: #F4F4F4;
                    display: flex;
                    padding: 10px  15px;
                    width: calc(100% - 110px);
                    border-radius: 7px;
                    margin-right: 10px;

                    p{
                        overflow: hidden;
                        margin: 0;
                        // min-width: 200px;
                        white-space: nowrap;
                    }

                    img{
                        margin-left: 10px;
                        cursor: pointer;
                    }
                }
            }

        }

    }

    &_income{
        padding: 20px;

        .filter-cancel{
            position: absolute;
            top: 20px;
            cursor: pointer;
            right: 20px;
        }

        .text1{
            margin: 20px 0 7px;
            font-size: 0.9em;
        }
        &-box1{
            &-main{
                // box-sizing: border-box;
                display: flex;
                &-form{
                    border: 1px solid #CCD8F3;
                    border-radius: 4px;
                    padding: 10px 20px;
                    box-sizing: border-box;
                    width: 100%;

                    &-rangelabel{
                        margin: 10px 0 5px;
                    }

                    &-range{
                        display: flex;
                        div{
                            width: 25%;
                            cursor: pointer;
                            border: 1px solid #D0D5DD;

                            p{
                                margin: 6px 0;
                                text-align: center;
                            }
                        }

                        .active{
                            color: white;
                            background: #0046E6;
                            border: 1px solid #0046E6;
                        }

                        .div1{
                            border-radius: 7px 0 0 7px;
                        }
                        .div2{
                            border-radius: 0 7px 7px 0;
                        }
                    }

                    &-amount{
                        display: flex;
                        justify-content: space-between;
                        margin-top: 20px;

                        p{
                            font-size: 1.5em;
                            margin: 0 10px;
                        }

                        input{
                            width: 100%;
                            height: 40px;
                            border-radius: 5px;
                            border: 1px solid #D0D5DD;
                            color: #667085;
                            padding: 0 10px;
                        }
                    }
                }

                &-delete{
                    width: 30px;
                    margin-left: 10px;
                    cursor: pointer;
                }
            }

            &-btns{
                margin-bottom: 30px;
                button{
                    width: 80px;
                    margin: 15px 10px 0 0;
                    padding: 6px 0;
                    border-radius: 4px;
                    border: 1px solid transparent;
                    img{
                        margin-top: -4px;
                    }
                }

                .and{
                    color: #006907;
                    background: #BED4BF;
                }
                .or{
                    color: #0046E6;
                    background: #CFE1F4;
                }
                .selected{
                    color: #535353;
                    background: #CDCDCD;
                    text-transform: uppercase;
                }
            }
        }

        &-save{
            background: #F4F4F4;
            padding: 30px 20px;
            // margin
            border-radius: 0 0 7px 7px ;
        }
    }
}